import React from "react";
import "./WhyDonate.css";

import imgUrl from "../../components/assets/img5.jpg";

const WhyDonate = () => {
  return (
    <div className="--grid-50 --p">
      <div className="--card left-side">
        <img className="left-img" src={imgUrl} alt="" />
      </div>
      <div className="--card right-side">
        <h4>Why the Education Can't Wait?</h4>
        <div className="--pxy1 --text">
          <h5>
            Children of today need continuous exposure to quality education,
            personal values, and life skills. They will grow up to become the
            decision maker, the trendsetters of tomorrow and they need to be
            equipped with an ideal upbringing.
          </h5>
          <br />
          <h5>
            Education of the entire generation was disrupted due to the
            pandemic, which has impacted marginalized children, the hardest.
            They are at risk of being pushed into poverty or forced to work as
            their families struggle to survive.
          </h5>
          <br />
          <h5>
            168 million children are facing learning losses that will lead to:
          </h5>
        </div>
        <div className="--text --pxy1">
          <ul>
            <li>
              <i className="bi bi-check-circle"></i> less access to higher
              education
            </li>
            <li>
              <i className="bi bi-check-circle"></i> lower future incomes
            </li>
            <li>
              <i className="bi bi-check-circle"></i> increasing unemployment
            </li>
            <li>
              Our children need quality education to have a better chance at a
              future. The years are not waiting and their
            </li>
          </ul>
          <h5>#EducationCan'tWait either</h5>
        </div>
      </div>
    </div>
  );
};

export default WhyDonate;
