import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/utils";
import ClientRoutes from "./Routes/ClientRoutes";
import AdminROutes from "./Routes/AdminROutes";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getLoginStatus } from "./redux/feattures/auth/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  axios.defaults.withCredentials = true;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoginStatus());
  }, [dispatch]);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/admin/*" element={<AdminROutes />} />
          <Route path="/*" element={<ClientRoutes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
