import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteSlider,
  getAllSlider,
} from "../../../redux/feattures/auth/authSlice";
import { backendUrl } from "../../../utils/utils";

const AdminSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sliders } = useSelector((state) => state.auth);

  //   console.log(sliders);
  useEffect(() => {
    dispatch(getAllSlider());
  }, [dispatch]);

  const handleDelete = async (id) => {
    if (window.confirm("Confirme !")) {
      await dispatch(deleteSlider(id));
      window.location.reload();
    }
  };
  const backUrl = backendUrl;
  return (
    <>
      <div className="add-events --width-100 --hr">
        <h1 className="--text-center --mb2 --p">Admin Slider</h1>
        <button onClick={() => navigate("/admin/dashboard/slider/add-slider")}>
          + Add
        </button>
      </div>
      <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
        {/* Content */}
        {sliders !== null && (
          <>
            {sliders.map((item, i) => (
              <div
                key={item.id}
                className="item --w-40 --card --pxy2 --text"
                data-value={i}
              >
                <img
                  style={{ objectFit: "cover", width: "100%" }}
                  src={`${backUrl}${item.image}`}
                  alt=""
                />
                <div className="--mt">
                  <h2 className="--mb">{item.title}</h2>
                  <p className="--text">{item.description}</p>
                  <p className="--text"> Button name: {item.btnName}</p>
                  <p className="--text">path: {item.btnPath}</p>
                </div>
                <div>
                  <div className="--flex-between --mt">
                    <a
                      href={`/admin/dashboard/slider/edit-slider/${item.id}`}
                      className="--btn --bg-1 --w-50"
                    >
                      Edit
                    </a>
                    <Link
                      onClick={() => handleDelete(item.id)}
                      className="--btn --bg-danger --w-50"
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default AdminSlider;
