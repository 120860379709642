import React from "react";
import rahul from "../../components/assets/m1.png";
import shiv from "../../components/assets/m3.png";
import gautam from "../../components/assets/m2.png";
import pappu from "../../components/assets/m4.png";
import raju from "../../components/assets/m5.png";
import bhavna from "../../components/assets/m6.png";
import swati from "../../components/assets/m7.png";
import sivani from "../../components/assets/m8.png";

const OurTeam = () => {
  let data = [
    {
      name: "ShivNarayn kumar",
      img: shiv,
    },
    {
      name: "Rahul Kumar",
      img: rahul,
    },
    {
      name: "Gautam Kumar",
      img: gautam,
    },
    {
      name: "Pappu Kumar",
      img: pappu,
    },
    {
      name: "Sivani Kumari",
      img: sivani,
    },
    {
      name: "Swati Kumari",
      img: swati,
    },
    {
      name: "Raju Kumar",
      img: raju,
    },
    {
      name: "Bhavna Chhetry",
      img: bhavna,
    },
  ];
  return (
    <section>
      <div className="--my2">
        <h1 style={{ fontSize: "7.5rem" }} className="--text-center">
          <u>
            <span>Our</span>{" "}
            <span style={{ color: "#BD3D76" }} className="--just">
              Team
            </span>
          </u>
        </h1>
      </div>
      <div className="--flex-dir-row --f-warp --my3 --px3 --cursor">
        {data.map((item, i) => (
          <div key={i} className="--c-h-w --center-all --card --pxy2 --mb2">
            <div className="--card-img">
              <img height={"200px"} src={item.img} alt="" />
            </div>
            <div className="--card-heading --text">
              {item.name} <br />
              (Volunteer)
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurTeam;
