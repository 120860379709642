import React from "react";

const OurStory = () => {
  return (
    <>
      <div className="--m3 --p1x">
        <div className="--text-justify --card --pxy3 --mt2">
          <h1
            style={{ fontSize: "6rem", letterSpacing: "4px" }}
            className="--text-center --font-k --just"
          >
            Mission
          </h1>
          <p className="--fw-bold">
            Breaking the Chains of Poverty through Education our mission is
            clear and resolute: to break the chain of continued poverty from
            generation to generation. We passionately believe that no child
            should be deprived of quality education, and we are dedicated to
            making this belief a reality. By focusing on education, we aim to
            empower each child with the tools and knowledge needed to break free
            from the cycle of poverty, ensuring a brighter future for
            generations to come. Our mission is a pledge to create a world where
            education becomes the key that unlocks doors to opportunity, dreams,
            and sustainable change.
          </p>
        </div>
        <div className="--text-justify --card --pxy3 --mt2">
          <h1
            style={{ fontSize: "6rem", letterSpacing: "4px" }}
            className="--text-center --font-k --just"
          >
            Vision
          </h1>
          <p className="--fw-bold">
            Nurturing Dreams, Creating Futures Our vision at Mission Pahal
            extends beyond the present into a future where every child's dream
            is nurtured and realized. We envision a world where education is not
            a luxury but a fundamental right, accessible to every child
            irrespective of their socio-economic background. Through quality
            education, we aspire to empower children to envision a future filled
            with possibilities, enabling them to become architects of their
            destinies. Our vision is to create a ripple effect, transforming
            communities and societies by investing in the potential of each
            child, one dream at a time.
          </p>
        </div>
        <div className="--text-justify --card --pxy3 --mt2">
          <h1
            style={{ fontSize: "6rem", letterSpacing: "4px" }}
            className="--text-center --font-k --just"
          >
            Team
          </h1>
          <p className="--fw-bold">
            A Collective Force for Change our team is the driving force behind
            our impactful initiatives. Comprising dedicated individuals who
            share a common passion for education and social change, our team
            works tirelessly to turn our mission and vision into reality. Each
            member brings unique skills, experiences, and perspectives,
            contributing to the diverse tapestry of our organization. Together,
            we are united by a singular purpose: to create lasting change in the
            lives of children facing adversity. Meet the faces behind Mission
            Pahal, a collective force committed to breaking barriers and
            fostering a brighter future for every child.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurStory;
