import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  getTestimonialById,
  updateTestimonial,
} from "../../../redux/feattures/auth/authSlice";

export const UpdateTestimonial = () => {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const { testimonial } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getTestimonialById());
  }, [dispatch]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = new FormData();
    eventData.append("title", title);
    eventData.append("description", description);
    eventData.append("image", image);

    // const eventData = {
    //   title: title,
    //   description: description,
    //   image: image,
    // };
    // console.log(eventData);
    // console.log(Object.fromEntries(eventData));
    await dispatch(updateTestimonial(eventData));
    window.location.reload();
  };

  useEffect(() => {
    if (testimonial) {
      setTitle(testimonial?.title);
      setDescription(testimonial?.description);
      // setImage(testimonial?.image);
    }
  }, [testimonial]);

  return (
    <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
      <div className="--text-center --width-100">
        <h2>New Testimonial</h2>
      </div>

      <div className="--w-80 new-evet">
        <form onSubmit={handleSubmit} className="--form-control">
          <div>Image:</div>
          <div className="--z-20">
            <label
              style={{
                border: "1px solid #ccc",
                display: "inline-block",
                padding: "20px 12px",
                cursor: "pointer",
                background: "#fff",
              }}
              htmlFor="image"
              className="--mb --width-100 --text-center"
            >
              Upluad Image
            </label>
          </div>
          <input
            onChange={(e) => setImage(e.target.files[0])}
            style={{
              position: "absolute",
              zIndex: -10,
              width: 0,
              height: 0,
              marginTop: "-9rem",
              marginLeft: "-1rem",
              background: "#fff",
              border: "#fff",
            }}
            type="file"
            id="image"
            name="image"
          />
          <br />
          <label htmlFor="title" className="--mb">
            Title:{" "}
          </label>
          <input
            onChange={(e) => setTitle(e.target.value)}
            style={{ height: "40px", padding: "5px 10px", fontSize: "1.6rem" }}
            className="--width-100"
            type="text"
            id="title"
            name="title"
            value={title}
            required
          />
          <br />
          <label htmlFor="description" className="--mb">
            Description:{" "}
          </label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            style={{
              padding: "10px 10px",
              fontSize: "1.6rem",
              minHeight: "20rem",
              maxHeight: "40rem",
            }}
            rows="5"
            className="--width-100"
            type="text"
            id="description"
            name="description"
            value={description}
            required
          />
          <br />
          <button
            type="submit"
            className="--btn --btn-lg --btn-block --btn-primary --py2"
          >
            Update Testimonial
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateTestimonial;
