import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllJoinedUser } from "../../../redux/feattures/auth/authSlice";

const Joined = () => {
  const dispatch = useDispatch();

  const { joined } = useSelector((state) => state.auth);
  const [paymentss, setPaymentss] = useState("");

  useEffect(() => {
    if (typeof joined !== "undefined" && joined !== null) {
      setPaymentss(Object.values(joined));
    }
  }, [setPaymentss, joined]);
  useEffect(() => {
    dispatch(getAllJoinedUser());
  }, [dispatch]);

  const data = [...paymentss];

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      maxWidth: "60px",
    },
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "180px",
    },
    {
      name: "name",
      selector: (row) => row.name,
      maxWidth: "150px",
    },
    {
      name: "email",
      selector: (row) => row.email,
      maxWidth: "170px",
    },
    {
      name: "phone",
      selector: (row) => row.phone,
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "gender",
      selector: (row) => row.gender,
      maxWidth: "50px",
    },
    {
      name: "address",
      selector: (row) => row.address,
      maxWidth: "200px",
    },
    {
      name: "message",
      selector: (row) => row.address,
    
    },
  ];

  const handleOnExport = () => {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);
    const createdDate = new Date();
    console.log(createdDate.getDate());
    const date =
      createdDate.getDate() +
      "-" +
      createdDate.getMonth() +
      1 +
      "-" +
      createdDate.getFullYear();
    console.log(date);
    /* Add Worksheet to workbook and return */
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `Joined_${date}.xlsx`);
  };

  return (
    <div className="--flex-center --cursor-pointer --flex-gap --f-warp">
      <h1>Joined</h1>
      <div className="--w-90">
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="495px"
          highlightOnHover
          actions={
            <button onClick={handleOnExport} className="--btn --btn-primary">
              Export
            </button>
          }
        />
      </div>
    </div>
  );
};

export default Joined;
