import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className={styles.footer}>
      <div className={styles.right}>
        <div>All Right Reserved &copy; {year}</div> <div>Mission Pahal.</div>
      </div>
      <div className={styles.our}>
        Developed with <i className="bi bi-balloon-heart-fill"></i> by{" "}
        <a
          rel="noreferrer"
          target="_blank"
          className={styles.link}
          href="https://www.instagram.com/lazyart.rahul/"
        >
          Rahul
        </a>{" "}
        &{" "}
        <a
          rel="noreferrer"
          target="_blank"
          className={styles.link}
          href="https://www.instagram.com/hey_tausif_here07/"
        >
          Tausif
        </a>
      </div>
    </div>
  );
};

export default Footer;
