import React from "react";
import "./Banner.css";

const Banner = ({ imgUrl, heading }) => {
  return (
    <div className="banner --center-all">
      <div className="--center-all heading">{heading}</div>
      <img height="300px" width="100%" src={imgUrl} alt="" />
    </div>
  );
};

export default Banner;
