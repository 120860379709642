import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteTestimonial,
  getAllTestimonial,
} from "../../../redux/feattures/auth/authSlice";
import { backendUrl } from "../../../utils/utils";

const AdminTestimonials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { testimonials } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAllTestimonial());
  }, [dispatch]);
  const handleDelete = async (id) => {
    if (window.confirm("Confirme !")) {
      await dispatch(deleteTestimonial(id));
      window.location.reload();
    }
  };
  const backUrl = backendUrl;
  return (
    <>
      <div className="add-events --width-100 --hr">
        <h1 className="--text-center --mb2 --p">Admin Testimonial</h1>
        <button
          onClick={() =>
            navigate("/admin/dashboard/testimonials/add-testimonials")
          }
        >
          + Add
        </button>
      </div>

      <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
        {/* content */}
        {testimonials?.map((item) => (
          <div
            key={item.id}
            className="item --w-40 --card --pxy2 --text"
            data-value="1"
          >
            <div className="--flex-dir-row --f-cloumn">
              <div sx={{ width: "150px" }}>
                <img
                  height="130"
                  width="130"
                  src={`${backUrl}${item.image}`}
                  alt=""
                />
              </div>
              <div className="--px2">
                <h4>{item.title}</h4>
                {item.description}
              </div>
            </div>
            <div>
              <div className="--flex-between --mt">
                <a
                  href={`/admin/dashboard/testimonials/edit-testimonial/${item.id}`}
                  className="--btn --bg-1 --w-50"
                >
                  Edit
                </a>
                <Link
                  onClick={() => handleDelete(item.id)}
                  className="--btn --bg-danger --w-50"
                >
                  Delete
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminTestimonials;
