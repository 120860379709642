import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createSlider } from "../../../redux/feattures/auth/authSlice";

const AddSlider = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [btnPath, setBtnPath] = useState("");
  const setBtnvalue = (e) => {
    setBtnPath(e.target.value);
    // console.log(e.target.value);
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    const eventData = new FormData();
    eventData.append("title", title);
    eventData.append("description", description);
    eventData.append("image", image);
    eventData.append("btnName", btnPath);
    if (btnPath === "Donate Now") {
      eventData.append("btnPath", "donation");
    } else {
      eventData.append("btnPath", "join-us");
    }
    // console.table(Object.fromEntries(eventData));

    await dispatch(createSlider(eventData));
    window.location.reload();
  };
  return (
    <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
      <h1>New Slider</h1>
      <br />
      <div className="--w-80 new-evet">
        <form onSubmit={handleSubmit} className="--form-control">
          <div>Image:</div>
          <div className="--z-20">
            <label
              style={{
                border: "1px solid #ccc",
                display: "inline-block",
                padding: "20px 12px",
                cursor: "pointer",
                background: "#fff",
              }}
              htmlFor="image"
              className="--mb --width-100 --text-center"
            >
              Upluad Image
            </label>
          </div>
          <input
            onChange={(e) => setImage(e.target.files[0])}
            style={{
              position: "absolute",
              zIndex: -10,
              width: 0,
              height: 0,
              marginTop: "-9rem",
              marginLeft: "-1rem",
              background: "#fff",
              border: "#fff",
            }}
            type="file"
            id="image"
            name="image"
            required
          />
          <br />
          <label htmlFor="title" className="--mb">
            Title:{" "}
          </label>
          <input
            onChange={(e) => setTitle(e.target.value)}
            style={{ height: "40px", padding: "5px 10px", fontSize: "1.6rem" }}
            className="--width-100"
            type="text"
            id="title"
            name="title"
            required
          />
          <br />
          <label htmlFor="description" className="--mb">
            Description:{" "}
          </label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            style={{ height: "40px", padding: "5px 10px", fontSize: "1.6rem" }}
            rows="5"
            className="--width-100"
            type="text"
            id="description"
            name="description"
            required
          />
          <br />
          <br />
          <div className="--flex-between">
            <div onChange={setBtnvalue} className="--w-48">
              <h4>Button Name:</h4>
              <div>
                <input
                  style={{
                    //   height: "40px",
                    margin: "5px 10px",
                    fontSize: "1.6rem",
                  }}
                  type="radio"
                  id="donation"
                  name="btnLink"
                  value={"Donate Now"}
                  required
                />
                <label htmlFor="btnLink" className="--mb">
                  Donate Now
                </label>
                <input
                  style={{
                    //   height: "40px",
                    margin: "5px 10px",
                    fontSize: "1.6rem",
                  }}
                  type="radio"
                  id="joun-us"
                  name="btnLink"
                  value={"Join-us"}
                  required
                />
                <label htmlFor="btnLink" className="--mb">
                  Join-us
                </label>
              </div>
            </div>
          </div>
          <br />
          <button
            type="submit"
            className="--btn --btn-lg --btn-block --btn-primary --py2"
          >
            Add Slider
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddSlider;
