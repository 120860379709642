import React from "react";
import { backendUrl, shortenText } from "../../utils/utils";
import { Link } from "react-router-dom";
// import p1 from "../assets/p1.png"; API key rzp_test_eSJNxTqrJwrb8I
// import p2 from "../assets/p2.png"; key secret nk5iWiHf3bebEidskb999njB

const RevP = ({ details, i }) => {
  const backUrl = backendUrl;
  return (
    <>
      <div className="--m2">
        {i % 2 !== 0 ? (
          <div className="--grid-50 --wrap-rev">
            <div>
              <h1 className="--font-k --text-left">{details.title}</h1>
              <div>
                <p className="--text-justify --p-text">
                  {shortenText(details.description, 800)
                    .split("\n")
                    .map((details, i) => (
                      <span key={i}>
                        {details}
                        <br />
                      </span>
                    ))}
                  <Link
                    className="--color-primary"
                    to={`/projects/${details.id}`}
                  >
                    {" "}
                    read more...
                  </Link>
                </p>
              </div>
            </div>
            <img
              className="object --mt"
              // height={350}
              src={`${backUrl}${details.image}`}
              alt=""
            />
          </div>
        ) : (
          <div className="--grid-50">
            <div className="--flex-center">
              <img
                className="object --mt"
                // height={350}
                src={`${backUrl}${details.image}`}
                alt=""
              />
            </div>
            <div>
              <h1 className="--font-k --text-left">{details.title}</h1>
              <div>
                <p className="--text-justify --p-text">
                  {shortenText(details.description, 800)
                    .split("\n")
                    .map((details, i) => (
                      <span key={i}>
                        {details}
                        <br />
                      </span>
                    ))}
                  <Link
                    className="--color-primary"
                    to={`/projects/${details.id}`}
                  >
                    {" "}
                    read more...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RevP;
