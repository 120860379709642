/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "./Slider.scss";

// import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
// import { sliderData } from "./slider-data";
import { useNavigate } from "react-router-dom";
import { backendUrl, useScrollLock } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlider } from "../../redux/feattures/auth/authSlice";
import Loader from "../Loader";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

    const { lockScroll, unlockScroll } = useScrollLock();

  const { sliders, isLoading } = useSelector((state) => state.auth);

  if(isLoading){
    lockScroll();
  } else{
    unlockScroll();
  }
  const [Project, setProject] = useState("");

  useEffect(() => {
    if (typeof sliders !== "undefined" && sliders !== null) {
      setProject(Object.values(sliders));
    }
  }, [setProject, sliders]);

  useEffect(() => {
    dispatch(getAllSlider());
  }, [dispatch]);

  const Projects = [...Project];
  // console.log(Projects);

  const slideLength = Projects.length;
  const autoScroll = true;
  let slideInterval;
  const intervalTime = 7000;

  const nextSlide = () => {
    // code to slide right
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  // const prevSlide = () => {
  //   // code to slide left
  //   setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  // };

  const backurl = backendUrl;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide, intervalTime, autoScroll]);

  return (
    <>
    {isLoading && <Loader />}
      <div className="slider --cursor-pointer">
        {/* <AiOutlineArrowLeft className="arrow prev" onClick={prevSlide} />
      <AiOutlineArrowRight className="arrow next" onClick={nextSlide} /> */}

        {Projects.map((slide, index) => {
          const { image, title, description, btnName, btnPath } = slide;

          return (
            <div
              key={index}
              className={index === currentSlide ? "slide current" : "slide"}
            >
              {index === currentSlide && (
                <>
                  <img src={`${backurl}${image}`} alt="slide" />
                  <h1>{title}</h1>
                  <div className="content">
                    <span className="span1"></span>
                    <span className="span2"></span>
                    <span className="span3"></span>
                    <span className="span4"></span>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <hr />
                    <button
                      className="--btn --btn-sec --z-20"
                      onClick={() => {
                        navigate(`/${btnPath}`);
                      }}
                    >
                      {btnName}
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Slider;
