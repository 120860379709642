import React from "react";
import { Link } from "react-router-dom";

import "./DonationCard.css";

const DonationCard = ({ donationAmt, handleClick, imgUrl, content }) => {
  return (
    <div className="--card --c-h-w --pxy2 --center-all">
      <div className="--card-img">
        <img src={imgUrl} alt="" />
      </div>
      <div className="--card-heading --py1_5 --text">{content}</div>
      <Link
        onClick={() => handleClick(donationAmt)}
        className="--card-button --text"
      >
        <span className="--mr">
          {" "}
          <i className="bi bi-gift"></i>
        </span>
        Donate <span>{donationAmt}</span>
      </Link>
    </div>
  );
};

export default DonationCard;
