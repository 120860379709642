import React, { useState } from "react";
import Banner from "../../components/Custom/Banner";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { joinUsUser } from "../../redux/feattures/auth/authSlice";
import bg from "../../components/assets/10.jpg";
import { useNavigate } from "react-router-dom";
import imgUrl from "../../components/assets/join.png";

const JoinUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [gender, setGander] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleJoinSubmit = async (e) => {
    e.preventDefault();
    if (!name || !mobile || !gender) {
      return toast.error("All fields are required");
    }
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }
    const userData = {
      name,
      email,
      mobile,
      address,
      message,
      gender,
    };
    await dispatch(joinUsUser(userData));
    // console.table(userData);
    navigate("/");
  };

  // banner data
  // let imgUrl =
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg";
  let heading = "";
  return (
    <>
      <Banner imgUrl={imgUrl} heading={heading} />
      <section className="--pxy2">
        <div className="--grid-50 --my2">
          <div className="--card --center-all d-m-none">
            <img
              width="100%"
              height={700}
              style={{ objectFit: "cover" }}
              src={bg}
              alt=""
            />
          </div>
          <div className="--card --pxy2">
            <form onSubmit={handleJoinSubmit} className=" --form-control">
              <div className="--text-20 --hr --text-dark">
                <div className="--flex-start --mb">Personal Information</div>
              </div>
              <div>
                <label htmlFor="name">Full Name</label>
                <input
                  className="--width-100 --my"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  className="--width-100 --my"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone">Phone (+91)</label>
                <input
                  className="--width-100 --my"
                  type="tel"
                  name="tel"
                  id="tel"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="--px">
                <label htmlFor="gender">Gender</label>

                <div className="--flex-start">
                  <div className="">
                    <input
                      className="--mx"
                      type="radio"
                      name="gender"
                      id="male"
                      value={"Male"}
                      onChange={(e) => setGander(e.target.value)}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="">
                    <input
                      className="--mx"
                      type="radio"
                      name="gender"
                      id="female"
                      value={"Female"}
                      onChange={(e) => setGander(e.target.value)}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="">
                    <input
                      className="--mx"
                      type="radio"
                      name="gender"
                      id="prefernottosay"
                      onChange={(e) => setGander(e.target.value)}
                      value={"Prefer not to say"}
                    />
                    <label htmlFor="prefernottosay">Prefer not to say</label>
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="name">Address</label>
                <input
                  className="--width-100 --my"
                  type="text"
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="name">Why you want to join us.</label>
                <textarea
                  style={{
                    height: "100px",
                    padding: "13px 10px",
                    overflow: "hidden",
                  }}
                  className="--width-100 --my"
                  type="text"
                  name="reason"
                  id="reason"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="--btn --btn-lg --btn-block --btn-success"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
