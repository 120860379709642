import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../redux/feattures/auth/authSlice";
import { backendUrl, shortenText } from "../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";

const IndividualEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { events } = useSelector((state) => state.auth);
  const [event, setEvent] = useState("");

  const [singleEvent, setSingleEvent] = useState();

  useEffect(() => {
    if (typeof events !== "undefined" && events !== null) {
      setEvent(Object.values(events));
    }
  }, [setEvent, events]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Events = [...event];
  // console.log(event);

  const restEvents = Events.filter((item) => item.id !== id);
  // console.log(restEvents);

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    let p = Events.find((item) => item.id === id);
    setSingleEvent(p);
  }, [setSingleEvent, Events, id]);
  // console.log(singleEvent);
  const backUrl = backendUrl;

  return (
    <>
      <div>
        {/* <h1 className="--flex-start --my2 --font-k">{singleEvent.title}</h1> */}
        {/* {typeof singleProject !== "undefined" && ( */}
        <div className="--width-100 --px3">
          <h1 className="--flex-start --my2 --font-k">{singleEvent?.title}</h1>
          <div className="--center-all">
            <div
              style={{ height: "400px", marginBottom: "3rem" }}
              className="--width-100"
            >
              <img
                height="400"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  objectPosition: "top",
                }}
                src={`${backUrl}${singleEvent?.image}`}
                alt=""
              />
            </div>
          </div>
          <div className="--mb">
            <p className="--mb --p-text">
              {singleEvent?.description &&
                singleEvent?.description.length > 0 &&
                singleEvent?.description.split("\n").map((item, id) => (
                  <span key={id}>
                    {item}
                    <br />
                  </span>
                ))}
            </p>
          </div>
        </div>
        {/* )} */}
        <br />
        <br />
        {restEvents && restEvents.length > 0 && (
          <div className="--my2 --px3">
            <h1 className="--mt4 --just-j">Other Events</h1>
            <div className="--grid-25 --cursor">
              <>
                {restEvents.splice(0, 3).map((data, i) => (
                  <>
                    <div
                      onClick={() => navigate(`/events/${data.id}`)}
                      key={data.id}
                      className="item --card --pxy2 --text"
                      data-value={data.id}
                    >
                      <div className="--flex-dir-row">
                        <div sx={{ width: "150px" }}>
                          <img
                            height="130"
                            width="130"
                            src={`${backUrl}${data.image}`}
                            alt=""
                          />
                        </div>
                        <div className="--px2">
                          <h4>{data.title}</h4>
                          {shortenText(data.description, 100)}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IndividualEvent;
