import React, { useState } from "react";
import "./Suscriber.css";

import aboutBanner from "../../components/assets/about-banner.png";
import { toast } from "react-toastify";
import { api } from "../../redux/feattures/apiConfig";

const Suscriber = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validateEmail(email)) {
    //   return toast.warning("Enter a valid eamil.");
    // }
    const d = {
      email,
    };
    const response = await api.post("/api/public/subscribe", d);

    if (response.data === "Suscribed Successfully") {
      toast.success("Suscribed Successfully");
    }
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };
  return (
    <div className="suscribers">
      <div className="banner">
        <img height="300px" width="100%" src={aboutBanner} alt="" />
        <div className="succriber nm --width-100 --flex-start">
          <div className="--flex-row --plc">
            <div className="s-heading">News Letter</div>
            <form
              onSubmit={handleSubmit}
              className="--form-control --flex-row f-warp"
              style={{ zIndex: 1 }}
            >
              <div className="--flex-center t">
                <label htmlFor="email" className="--mx"></label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@gmail.com"
                  className="--px"
                  required
                />
              </div>
              <div>
                <button type="submit" className="--btn --btn-right-icon">
                  <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suscriber;
