import React from "react";

import "./FooterLinks.scss";
import { Link } from "react-router-dom";
import logoImg from "../assets/logo.png";
import facebook from "../assets/facebook.png";
import i from "../assets/i.png";
import twitter from "../assets/twitter.png";
import whatsapp from "../assets/whatsapp.png";

const FooterLinks = () => {
  return (
    <>
      <section className="footer-section">
        <div className="--grid-25 --hr --div">
          <div className="footer-logo">
            <img height={70} src={logoImg} alt={logoImg} />
            <h1 className="--text-light --ml">Mission Pahal</h1>
          </div>
          <div className="footer-icons">
            <h4 className="--text-light">Follow Us On</h4>

            <span>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100079154242688&mibextid=ZbWKwL"
              >
                {/* <i className="bi bi-facebook"></i> */}
                <img height={"30px"} src={facebook} alt={facebook} />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/missionpahal/"
              >
                <img height={"30px"} src={i} alt={i} />
                {/* <i className="bi bi-instagram"></i> */}
              </a>
              <Link
                // rel="noreferrer"
                // target="_blank"
                href="/"
              >
                <img height={"30px"} src={twitter} alt={twitter} />
                {/* <i className="bi bi-instagram"></i> */}
              </Link>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/message/63AYDPVB3B66F1"
              >
                <img height={"30px"} src={whatsapp} alt={whatsapp} />
                {/* <i className="bi bi-instagram"></i> */}
              </a>
            </span>
          </div>
        </div>
        <div className="footer-links --px3">
          <ul className="--mb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link className="--content" to="/aboutUs">
                About Us
              </Link>
            </li>
            <li>
              <Link className="--content" to="/events">
                Events
              </Link>
            </li>
            <li>
              <Link className="--content" to="/projects">
                Projects
              </Link>
            </li>
            <li>
              <Link className="--content" to="/our-team">
                Our Team
              </Link>
            </li>
            <li>
              <Link className="--content" to="/join-us">
                Join Us
              </Link>
            </li>
            <li>
              <Link className="--content" to="/volunteer-registration-form">
                Volunteer
              </Link>
            </li>
            <li>
              <Link className="--content" to="/gallery">
                Gallery
              </Link>
            </li>
            <li>
              <Link className="--content" to="/donation">
                Donate Now
              </Link>
            </li>
          </ul>
          <div className="--text-light">
            <h4 className="--text-light">Contact Us</h4>
            <span>
              <b> Office Address : </b> E0, Anurag Palace Block-B, Kishoreganj
              Road No.1, Ranchi Jharkhand, 834001,
              <br />
            </span>
            <span>
              {" "}
              <b>
                <i className="bi bi-telephone"></i> :-{" "}
              </b>{" "}
              +91 9801810181, +91 9572570256
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterLinks;
