import React, { useEffect } from "react";
import "./AdminEvents.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEvent,
  getEvents,
} from "../../../redux/feattures/auth/authSlice";
import { backendUrl } from "../../../utils/utils";

const AdminEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { events } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const handleDelete = async (id) => {
    if (window.confirm("Confirme !")) {
      await dispatch(deleteEvent(id));
      window.location.reload();
    }
  };
  const backUrl = backendUrl;
  return (
    <>
      <div className="add-events --width-100 --hr">
        <h1 className="--text-center --mb2 --p">Admin Events</h1>
        <button onClick={() => navigate("/admin/dashboard/events/add-event")}>
          + Add events
        </button>
      </div>
      <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
        <div className="--w-90 --flex-center --flex-gap --f-warp">
          {/* content */}
          {events !== null ? (
            <>
              {events?.map((event) => (
                <div
                  key={event.id}
                  // onClick={(e) => console.log(e.target.value)}
                  className="--event-card --card --pxy2"
                >
                  <img src={`${backUrl}${event.image}`} alt="" />
                  <h4>{event.title}</h4>
                  <p className="--mt --text-justify">{event.description}</p>
                  <div className="--flex-between --mt">
                    <a
                      href={`/admin/dashboard/events/edit-event/${event.id}`}
                      className="--btn --bg-1 --w-50"
                    >
                      Edit
                    </a>
                    <Link
                      onClick={() => handleDelete(event.id)}
                      className="--btn --bg-danger --w-50"
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h1>No Events</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminEvents;
