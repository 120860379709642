import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: false,
  user: null,
  events: null,
  event: null,
  testimonials: null,
  testimonial: null,
  sliders: null,
  slider: null,
  projects: null,
  project: null,
  payments: null,
  paymentGraph: [],
  gallery: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  getPaymentWithId: null,
  volunteer: null,
  suscribers: null,
  joined: null,
  totalAmount: null,
  volLen: null,
  sucLen: null,
  joinLen: null,
};

// Register User
export const register = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      return await authService.register(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Register User
export const joinUsUser = createAsyncThunk(
  "auth/joinUsUser",
  async (userData, thunkAPI) => {
    try {
      return await authService.joinUsUser(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Register User
export const getAllJoinedUser = createAsyncThunk(
  "auth/getAllJoinedUser",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllJoinedUser();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login User
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout User
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    return await authService.logout();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // Throwing the error so it can be caught in redux-saga
    return thunkAPI.rejectWithValue(message);
  }
});

// Get Login Status
export const getLoginStatus = createAsyncThunk(
  "auth/getLoginStatus",
  async (_, thunkAPI) => {
    try {
      return await authService.getLoginStatus();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user
export const getUser = createAsyncThunk("auth/getUser", async (_, thunkAPI) => {
  try {
    return await authService.getUser();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // Throwing the error so it can be caught in redux-saga
    return thunkAPI.rejectWithValue(message);
  }
});

// Update user
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (userData, thunkAPI) => {
    try {
      return await authService.updateUser(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Photo
export const updatePhoto = createAsyncThunk(
  "auth/updatePhoto",
  async (userData, thunkAPI) => {
    try {
      return await authService.updatePhoto(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create Event
export const createEvent = createAsyncThunk(
  "auth/createEvent",
  async (eventData, thunkAPI) => {
    try {
      return await authService.createEvent(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get All Event
export const getEvents = createAsyncThunk(
  "auth/getEvents",
  async (_, thunkAPI) => {
    try {
      return await authService.getEvents();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Event
export const getEvent = createAsyncThunk(
  "auth/getEvent",
  async (_, thunkAPI) => {
    try {
      return await authService.getEvent();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Event
export const updateEvent = createAsyncThunk(
  "auth/updateEvent",
  async (eventData, thunkAPI) => {
    try {
      return await authService.updateEvent(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Deleate Event
export const deleteEvent = createAsyncThunk(
  "auth/deleteEvent",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteEvent(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create testimonial
export const createTestimonial = createAsyncThunk(
  "auth/createTestimonial",
  async (testimonialData, thunkAPI) => {
    try {
      return await authService.createTestimonial(testimonialData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All Testimonial
export const getAllTestimonial = createAsyncThunk(
  "auth/getAllTestimonial",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllTestimonial();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get testimonial by id
export const getTestimonialById = createAsyncThunk(
  "auth/getTestimonialById",
  async (_, thunkAPI) => {
    try {
      return await authService.getTestimonialById();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Deleate Testimonial
export const deleteTestimonial = createAsyncThunk(
  "auth/deleteTestimonial",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteTestimonial(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update testimonial
export const updateTestimonial = createAsyncThunk(
  "auth/updateTestimonial",
  async (eventData, thunkAPI) => {
    try {
      return await authService.updateTestimonial(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create projects
export const createProject = createAsyncThunk(
  "auth/createProject",
  async (eventData, thunkAPI) => {
    try {
      return await authService.createProject(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get All projects
export const getAllProjects = createAsyncThunk(
  "auth/getAllProjects",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllProjects();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get project
export const getProject = createAsyncThunk(
  "auth/getProject",
  async (_, thunkAPI) => {
    try {
      return await authService.getProject();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update project
export const updateProject = createAsyncThunk(
  "auth/updateProjects",
  async (eventData, thunkAPI) => {
    try {
      return await authService.updateProject(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Deleate project
export const deleteProject = createAsyncThunk(
  "auth/deleteProject",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteProject(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create slider
export const createSlider = createAsyncThunk(
  "auth/createSlider",
  async (sliderData, thunkAPI) => {
    try {
      return await authService.createSlider(sliderData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get All slider
export const getAllSlider = createAsyncThunk(
  "auth/getAllSlider",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllSlider();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update slider
export const updateSlider = createAsyncThunk(
  "auth/updateSlider",
  async (eventData, thunkAPI) => {
    try {
      return await authService.updateSlider(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get slider
export const getSliderById = createAsyncThunk(
  "auth/getSliderById",
  async (_, thunkAPI) => {
    try {
      return await authService.getSliderById();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Deleate Slider
export const deleteSlider = createAsyncThunk(
  "auth/deleteSlider",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteSlider(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create Gallery
export const createGallery = createAsyncThunk(
  "auth/createGallery",
  async (galleryImage, thunkAPI) => {
    try {
      return await authService.createGallery(galleryImage);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Deleate Slider
export const deleteGallery = createAsyncThunk(
  "auth/deleteGallery",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteGallery(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All slider
export const getAllGallery = createAsyncThunk(
  "auth/getAllGallery",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllGallery();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get All projects
export const getPayments = createAsyncThunk(
  "auth/getPayments",
  async (_, thunkAPI) => {
    try {
      return await authService.getPayments();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Graph Data
export const getGraphData = createAsyncThunk(
  "auth/getGraphData",
  async (_, thunkAPI) => {
    try {
      return await authService.getGraphData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get getPaymentById
export const getPaymentById = createAsyncThunk(
  "auth/paymetDataById",
  async (_, thunkAPI) => {
    try {
      return await authService.getPaymentById();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getAllSuscribers
export const getAllSuscribers = createAsyncThunk(
  "auth/getAllSuscribers",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllSuscribers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getAllVolunteer
export const getAllVolunteer = createAsyncThunk(
  "auth/getAllVolunteer",
  async (_, thunkAPI) => {
    try {
      return await authService.getAllVolunteer();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getVolunteerById
export const getVolunteerById = createAsyncThunk(
  "auth/getVolunteerById",
  async (_, thunkAPI) => {
    try {
      return await authService.getVolunteerById();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// updateVolunteer
export const updateVolunteer = createAsyncThunk(
  "auth/updateVolunteer",
  async (eventData, thunkAPI) => {
    try {
      return await authService.updateVolunteer(eventData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Deleate Slider
export const deleteVolunteer = createAsyncThunk(
  "auth/deleteVolunteer",
  async (id, thunkAPI) => {
    try {
      return await authService.deleteVolunteer(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get getDashboardCount

export const getTotalAmount = createAsyncThunk(
  "auth/getTotalAmount",
  async (_, thunkAPI) => {
    try {
      return await authService.getTotalAmount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVolunteerCount = createAsyncThunk(
  "auth/getVolunteerCount",
  async (_, thunkAPI) => {
    try {
      return await authService.getVolunteerCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSubscribeCount = createAsyncThunk(
  "auth/getSubscribeCount",
  async (_, thunkAPI) => {
    try {
      return await authService.getSubscribeCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getJoinUsCount = createAsyncThunk(
  "auth/getJoinUsCount",
  async (_, thunkAPI) => {
    try {
      return await authService.getJoinUsCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Throwing the error so it can be caught in redux-saga
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    RESET_AUTH(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Register User
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        // toast.error(action.payload);
      })
      // Register User
      .addCase(joinUsUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(joinUsUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        // state.user = action.payload;
        toast.success("Joined Succsessful");
      })
      .addCase(joinUsUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        // toast.error(action.payload);
      })
      //getAllJoinedUser
      .addCase(getAllJoinedUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJoinedUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.joined = action.payload;
      })
      .addCase(getAllJoinedUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        // toast.error(action.payload);
      })
      // Login User
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.user = action.payload;
        toast.success("Login Succsessful");
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Logout User
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = false;
        state.user = null;
        toast.success(action.payload);
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // getLoginStatus
      .addCase(getLoginStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLoginStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = action.payload;
        if (action.payload.message === "invalid signature") {
          state.isLoggedIn = false;
        }
      })
      .addCase(getLoginStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // getUser
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })

      // Create Event
      .addCase(createEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.events = action.payload;
        toast.success("Event Created Succsessful");
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.events = null;
        toast.success(action.payload);
      })
      // get All Events
      .addCase(getEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // getEvent
      .addCase(getEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.event = action.payload;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })

      // update Event
      .addCase(updateEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.event = action.payload;
        toast.success("Event Updated Succsessful");
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })

      // delete Event
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Event Deleted Successfully");
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // Create prject
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.project = action.payload;
        toast.success("Project Created Succsessful");
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.project = null;
        toast.success(action.payload);
      })
      // get All projects
      .addCase(getAllProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.projects = action.payload;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // get project
      .addCase(getProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.project = action.payload;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // update project
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.project = action.payload;
        toast.success("Project Updated Succsessful");
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // delete project
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Project Deleted Successfully");
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // Create Testimonial
      .addCase(createTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTestimonial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.testimonials = action.payload;
        toast.success("Testimonial Created Succsessful");
      })
      .addCase(createTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.testimonials = null;
        toast.success(action.payload);
      })
      // get All testimonials
      .addCase(getAllTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTestimonial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.testimonials = action.payload;
      })
      .addCase(getAllTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // get Testimonial
      .addCase(getTestimonialById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTestimonialById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.testimonial = action.payload;
      })
      .addCase(getTestimonialById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // update Event
      .addCase(updateTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTestimonial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.testimonials = action.payload;
        toast.success("Testimonial Updated Succsessful");
      })
      .addCase(updateTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // delete testimonial
      .addCase(deleteTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTestimonial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Testimonial Deleted Successfully");
      })
      .addCase(deleteTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // Create slider
      .addCase(createSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSlider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.sliders = action.payload;
        toast.success("Slider Created Succsessful");
      })
      .addCase(createSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.events = null;
        toast.success(action.payload);
      })
      // get All slider
      .addCase(getAllSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSlider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.sliders = action.payload;
      })
      .addCase(getAllSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // update slider
      .addCase(updateSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSlider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.sliders = action.payload;
        toast.success("Slider Updated Succsessful");
      })
      .addCase(updateSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // get Testimonial
      .addCase(getSliderById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSliderById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.slider = action.payload;
      })
      .addCase(getSliderById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // delete Slder
      .addCase(deleteSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Slider Deleted Successfully");
      })
      .addCase(deleteSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // get All gallery
      .addCase(getAllGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.gallery = action.payload;
      })
      .addCase(getAllGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // Create gallery
      .addCase(createGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.gallery = action.payload;
        toast.success("Gallery image uploaded Succsessful");
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.events = null;
        toast.success(action.payload);
      })
      // delete gallery
      .addCase(deleteGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Gallery Image Deleted Successfully");
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //Get all Payments
      .addCase(getPayments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.payments = action.payload;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //Get all Grapg data
      .addCase(getGraphData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.paymentGraph = action.payload;
      })
      .addCase(getGraphData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })

      //GetgetPaymentById
      .addCase(getPaymentById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.getPaymentWithId = action.payload;
      })
      .addCase(getPaymentById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //volunteer
      .addCase(getAllVolunteer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllVolunteer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.volunteer = action.payload;
      })
      .addCase(getAllVolunteer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //getVolunteerById
      .addCase(getVolunteerById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVolunteerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.volunteer = action.payload;
      })
      .addCase(getVolunteerById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //getVolunteerById
      .addCase(deleteVolunteer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVolunteer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        toast.success("Volunteer deleted successfully");
      })
      .addCase(deleteVolunteer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      // updateVolunteer
      .addCase(updateVolunteer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVolunteer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.volunteer = action.payload;
        toast.success("Volunteer Updated Succsessful");
      })
      .addCase(updateVolunteer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //suscribers
      .addCase(getAllSuscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSuscribers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.suscribers = action.payload;
      })
      .addCase(getAllSuscribers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //Get total amount
      .addCase(getTotalAmount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTotalAmount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.totalAmount = action.payload;
      })
      .addCase(getTotalAmount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //Get Volunteer Count
      .addCase(getVolunteerCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVolunteerCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.volLen = action.payload;
      })
      .addCase(getVolunteerCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      //Get Subscriber Count
      .addCase(getSubscribeCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscribeCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.sucLen = action.payload;
      })
      .addCase(getSubscribeCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      .addCase(getJoinUsCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJoinUsCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.joinLen = action.payload;
      })
      .addCase(getJoinUsCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      });
  },
});

export const { RESET_AUTH } = authSlice.actions;

export default authSlice.reducer;
