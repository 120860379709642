/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "../PageNotFound";
import Footer from "../components/Footer/Footer";
import FooterLinks from "../components/Footer/FooterLinks";
import IndividualEvent from "../pages/Events/IndividualEvent";
import EventsPage from "../pages/Events/EventsPage";
import VolunteerRegPage from "../pages/Volunteer/VolunteerRegPage";
import SingleProject from "../pages/Project/SingleProject";
import ProjectPage from "../pages/Project/ProjectPage";
import OurTeam from "../pages/Team/OurTeam";
import JoinUs from "../pages/Join-us/JoinUs";
import GalleryPage from "../pages/Gallery/GalleryPage";
import PaymentSuccess from "../components/paymentSR/PaymentSuccess";
import DonationPage from "../pages/Donation/DonationPage";
import AboutPage from "../pages/About/AboutPage";
import HomePage from "../pages/Home/HomePage";
import Navbar from "../components/Header/Navbar";

const ClientRoutes = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/aboutUs" element={<AboutPage />} />
        <Route path="/donation" element={<DonationPage />} />
        <Route path="/donation/success/:id" element={<PaymentSuccess />} />

        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/projects/:id" element={<SingleProject />} />
        <Route
          path="/volunteer-registration-form"
          element={<VolunteerRegPage />}
        />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events/:id" element={<IndividualEvent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <FooterLinks />
      <Footer />
    </>
  );
};

export default ClientRoutes;
