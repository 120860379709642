import axios from "axios";

export const API_BASE_URL = "https://root.missionpahal.org";

const jwt = localStorage.getItem("AdminVerifivationToken");

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
});
