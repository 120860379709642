import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../redux/feattures/auth/authSlice";
import Banner from "../../components/Custom/Banner";
import { backendUrl, shortenText } from "../../utils/utils";
import { Link } from "react-router-dom";
import imgUrl from "../../components/assets/banner4.png";
const ProjectPage = () => {
  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.auth);
  const [Project, setProject] = useState("");

  useEffect(() => {
    if (typeof projects !== "undefined" && projects !== null) {
      setProject(Object.values(projects));
    }
  }, [setProject, projects]);

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const Projects = [...Project];
  const backUrl = backendUrl;

  // let imgUrl =
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg";
  let heading = "Projects";

  return (
    <>
      {/* banner here */}
      <Banner imgUrl={imgUrl} heading={heading} />
      <section className="--px2y3">
        <div>
          {Projects.map((item, i) => (
            <div className="--mb" key={item.id}>
              <>
                <div id={item.id} className="--m2">
                  {i % 2 !== 0 ? (
                    <div className="--grid-50 --wrap-rev">
                      <div className="--text-justify">
                        <h1 className="--font-k">{item.title}</h1>
                        <div>
                          <p className="--p-text">
                            {shortenText(item.description, 800)
                              .split("\n")
                              .map((item, id) => (
                                <span key={id}>
                                  {item}
                                  <br />
                                </span>
                              ))}
                            <Link
                              className="--color-primary"
                              to={`/projects/${item.id}`}
                            >
                              {" "}
                              read more...
                            </Link>
                          </p>
                        </div>
                      </div>
                      <img
                        className="object --mt"
                        // height={350}
                        src={`${backUrl}${item.image}`}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="--grid-50">
                      <div className="--flex-center">
                        <img
                          className="object --mt"
                          // height={350}
                          src={`${backUrl}${item.image}`}
                          alt=""
                        />
                      </div>
                      <div className="--text-justify">
                        <h1 className="--font-k">{item.title}</h1>
                        <div>
                          <p className="--p-text">
                            {shortenText(item.description, 800)
                              .split("\n")
                              .map((item, id) => (
                                <span key={id}>
                                  {item}
                                  <br />
                                </span>
                              ))}
                            <Link
                              className="--color-primary"
                              to={`/projects/${item.id}`}
                            >
                              {" "}
                              read more...
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ProjectPage;
