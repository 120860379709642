/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { backendUrl } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVolunteer,
  getAllVolunteer,
} from "../../../redux/feattures/auth/authSlice";

const Volunteer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { volunteer } = useSelector((state) => state.auth);

  useEffect(() => {
    if (volunteer === null || typeof volunteer === "undefined") {
      dispatch(getAllVolunteer());
    }
  }, [dispatch, volunteer]);

  const handleDelete = (id) => {
    if (window.confirm("Confirme !")) {
      dispatch(deleteVolunteer(id));
      window.location.reload();
    }
  };
  // const backUrl = backendUrl;
  return (
    <>
      <div className="add-events --width-100 --hr">
        <h1 className="--text-center --mb2 --p">Volunteer</h1>
        <button onClick={() => navigate("/volunteer-registration-form")}>
          + Add
        </button>
      </div>
      <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
        {volunteer !== null ? (
          volunteer?.map((item) => (
            <div
              key={item.id}
              style={{ width: "25rem" }}
              className="item --card --pxy2 --text"
              data-value="1"
            >
              <div className="--flex-dir-column --align-center">
                <div sx={{ width: "160px" }}>
                  <img
                    height="160"
                    width="140"
                    src={`${backendUrl}${item.profileImg}`}
                    alt=""
                  />
                </div>
                <div className="--px2 --text-center">
                  <h4>{item.name}</h4>
                  <b>{item.mobile}</b>
                </div>
                <br />
                <div>
                  <ul>
                    <li>Email : {item.email}</li>
                    <li>Gender : {item.gender}</li>
                    <li>Intrest : {item.intrest}</li>
                    <li>How Know : {item.howKnow}</li>
                    <li>Profession : {item.profession}</li>
                    <li>Location : {item.address}</li>
                    <li>DOB : {item.dob}</li>
                    <li>Experience : {item.experience}</li>
                  </ul>
                </div>
              </div>
              <br />
              <div>
                <div className="--flex-between --mt">
                  <a
                    href={`/admin/dashboard/volunteer/update-volenter/${item.id}`}
                    className="--btn --bg-1 --w-50"
                  >
                    Edit
                  </a>
                  <Link
                    onClick={() => handleDelete(item.id)}
                    className="--btn --bg-danger --w-50"
                  >
                    Delete
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <h1>No volunteer</h1>
          </>
        )}
      </div>
    </>
  );
};

export default Volunteer;
