import React, { useEffect, useState } from "react";
import Banner from "../../components/Custom/Banner";
import "./GalleryPage.css";
// import GalleryCard from "./GalleryCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { backendUrl, useScrollLock } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllGallery } from "../../redux/feattures/auth/authSlice";
import imgUrl from "../../components/assets/banner3.png";
import Loader from "../../components/Loader";

const GalleryPage = () => {
  // let imgUrl = "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg";
  let heading = "Our Gallery";
  heading = heading.toUpperCase();

  const dispatch = useDispatch();

  const { gallery, isLoading } = useSelector((state) => state.auth);
  const [image, setImages] = useState("");

  const backUrl = backendUrl;

  // const images = [
  //   "https://picsum.photos/200/300?image=1050",
  //   "https://picsum.photos/300/300?image=206",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  //   "https://picsum.photos/200/300?image=1050",
  //   "https://picsum.photos/300/300?image=206",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  //   "https://picsum.photos/200/300?image=1050",
  //   "https://picsum.photos/300/300?image=206",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  // ];

  useEffect(() => {
    if (typeof gallery !== "undefined" && gallery !== null) {
      setImages(Object.values(gallery));
    }
  }, [setImages, gallery]);

  useEffect(() => {
    dispatch(getAllGallery());
  }, [dispatch]);

  const images = [...image];
  // console.log(images);
  const { lockScroll, unlockScroll } = useScrollLock();

  const [viewer, setViewer] = useState(false);

  const closeViewer = () => {
    setViewer(false);
    unlockScroll();
  };

  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setData({ img, i });
    setViewer(true);
    lockScroll();
  };

  
   if (isLoading) {
     lockScroll();
   } else {
     unlockScroll();
   }

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      if (i < images.length - 1) {
        setData({ img: images[i + 1].image, i: i + 1 });
      } else {
        i = 0;
        setData({ img: images[i].image, i: i });
      }
    }
    if (action === "prev-img") {
      if (i > 0) {
        setData({ img: images[i - 1].image, i: i - 1 });
      } else {
        i = images.length;
        setData({ img: images[i - 1].image, i: i - 1 });
      }
    }
  };

  return (
    <>
    {isLoading && <Loader />}
      <section>
        <Banner imgUrl={imgUrl} heading={heading} />
        <br />
        <br />
        {viewer && (
          <>
            <div className="--center-all --cursor-pointer --p-a">
              <div className="--center-all"></div>
              <div className="--card --my --main-box --p-f-b --center-all">
                <div className="img-box">
                  <img src={`${backUrl}${data.img}`} alt={data.i} />
                </div>
                <AiOutlineArrowLeft
                  style={{
                    background: "transparent",
                    width: "2.5rem",
                    height: "5rem",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "5rem",
                    color: "#000",
                    zIndex: 5,
                  }}
                  onClick={() => imgAction("prev-img")}
                />
                <AiOutlineArrowRight
                  style={{
                    background: "trnaparent",
                    width: "2.5rem",
                    height: "5rem",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    right: "5rem",
                    color: "#000",
                    zIndex: 5,
                  }}
                  onClick={() => imgAction("next-img")}
                />
                <div
                  onClick={closeViewer}
                  style={{
                    position: "absolute",
                    top: "0rem",
                    right: "0rem",
                    fontSize: "2rem",
                    fontWeight: "900",
                    background: "#fff",
                    padding: ".3rem",
                    cursor: "pointer",
                    zIndex: 20,
                  }}
                >
                  <i className="bi bi-x-lg"></i>
                </div>
              </div>
            </div>
            <div onClick={closeViewer} className="blur-bg"></div>
          </>
        )}

        <div className="--p1x">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry columnsCount={3} gutter="20px">
              {images?.map((item, i) => (
                <img
                  alt={item.image}
                  key={i}
                  src={`${backUrl}${item.image}`}
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  onClick={() => viewImage(item.image, i)}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>

        <br />
        <br />
      </section>
    </>
  );
};

export default GalleryPage;
