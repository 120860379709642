import React from "react";
import Banner from "../../components/Custom/Banner";
import aboutBanner from "../../components/assets/defaultBanner.png";
import OurStory from "./OurStory";
import Map from "../../components/Map/Map";

const AboutPage = () => {
  let heading = "About Us";
  heading = heading.toUpperCase();
  return (
    <section>
      <Banner color={"black"} imgUrl={aboutBanner} heading={heading} />
      <OurStory />
      <Map />
    </section>
  );
};

export default AboutPage;
