import React from "react";

const AddVolunteer = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // const formData = new FormData();
    let image = document.querySelector("#image").value;
    let name = document.querySelector("#name").value;
    let number = document.querySelector("#number").value;

    console.log(image, name, number);
  };
  return (
    <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
      <h1>Add Volunteer</h1>
      <br />
      <div className="--w-80 new-evet">
        <form onSubmit={handleSubmit} className="--form-control">
          <div>Image:</div>
          <div className="--z-20">
            <label
              style={{
                border: "1px solid #ccc",
                display: "inline-block",
                padding: "20px 12px",
                cursor: "pointer",
                background: "#fff",
              }}
              htmlFor="image"
              className="--mb --width-100 --text-center"
            >
              Upluad Image
            </label>
          </div>
          <input
            style={{
              position: "absolute",
              zIndex: -10,
              width: 0,
              height: 0,
              marginTop: "-9rem",
              marginLeft: "-1rem",
              background: "#fff",
              border: "#fff",
            }}
            type="file"
            id="image"
            name="image"
            required
          />
          <br />
          <label htmlFor="title" className="--mb">
            Name:{" "}
          </label>
          <input
            style={{ height: "40px", padding: "5px 10px", fontSize: "1.6rem" }}
            className="--width-100"
            type="text"
            id="name"
            name="name"
            required
          />
          <br />
          <label htmlFor="description" className="--mb">
            Mobile Number:{" "}
          </label>
          <input
            style={{ height: "40px", padding: "5px 10px", fontSize: "1.6rem" }}
            className="--width-100"
            type="number"
            id="number"
            name="number"
            required
          />
          <br />
          <button
            type="submit"
            className="--btn --btn-lg --btn-block --btn-primary --py2"
          >
            Add Testimonial
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddVolunteer;