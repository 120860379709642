import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RESET_AUTH, logout } from "../../redux/feattures/auth/authSlice";

const activeLink = ({ isActive }) => (isActive ? "activeDlink" : "");

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutUser = async () => {
    await dispatch(logout());
    await dispatch(RESET_AUTH());
    navigate("/");
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-container">
        <div className="grid1 --hr">
          <h3 className="--text-light --mt">Admin Panel</h3>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            {/* Hamburger icon */}
            {isOpen ? (
              <i className="bi bi-x-lg --text-md --text-light"></i>
            ) : (
              <i className="bi bi-list --text-md --text-light"></i>
            )}
          </button>
        </div>
        <div className="grid2 --mt2 --text-smd">
          <div className="navlink">
            <NavLink to={"/admin/dashboard/"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Dashboard</div>
                <div className="--text-20">
                  <i className="bi bi-speedometer"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navlink">
            <NavLink to={"/admin/dashboard/payments"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Payments</div>
                <div className="--text-20">
                  <i className="bi bi-cash-stack"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navlink">
            <NavLink to={"/admin/dashboard/volunteer"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Volunteer</div>
                <div className="--text-20">
                  <i className="bi bi-person-lines-fill"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navlink">
            <NavLink to={"/admin/dashboard/suscribers"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Suscribers</div>
                <div className="--text-20">
                  <i className="bi bi-terminal"></i>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="navlink">
            <NavLink to={"/admin/dashboard/joined"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Joined User</div>
                <div className="--text-20">
                  <i className="bi bi-terminal"></i>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="navlink">
            <NavLink to={"/admin/dashboard/events"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Events</div>
                <div className="--text-20">
                  <i className="bi bi-calendar-event"></i>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="navlink">
            <NavLink to={"/admin/dashboard/projects"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Projects</div>
                <div className="--text-20">
                  <i className="bi bi-card-list"></i>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="navlink">
            <NavLink
              to={"/admin/dashboard/testimonials"}
              className={activeLink}
            >
              <div className="--flex-between link --pxy">
                <div>Testimonials</div>
                <div className="--text-20">
                  <i className="bi bi-calendar3-range"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navlink">
            <NavLink to={"/admin/dashboard/slider"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Slider</div>
                <div className="--text-20">
                  <i className="bi bi-sliders"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navlink">
            <NavLink to={"/admin/dashboard/gallery"} className={activeLink}>
              <div className="--flex-between link --pxy">
                <div>Gallery</div>
                <div className="--text-20">
                  <i className="bi bi-columns-gap"></i>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="logoutBtn">
            <div
              style={{ position: "relative", top: "3rem" }}
              className="--pxy --btn --btn-danger"
              onClick={logoutUser}
            >
              Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
