/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./DashBoard.scss";
import ShowChart from "../component/Chart/Chart";
import { useDispatch, useSelector } from "react-redux";
import {
  getJoinUsCount,
  getSubscribeCount,
  getTotalAmount,
  getVolunteerCount,
} from "../../redux/feattures/auth/authSlice";
import { useNavigate } from "react-router-dom";

const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalAmount, volLen, sucLen, joinLen, isLoggedIn } = useSelector(
    (state) => state.auth
  );



  useEffect(() => {
    dispatch(getTotalAmount());
    dispatch(getVolunteerCount());
    dispatch(getSubscribeCount());
    dispatch(getJoinUsCount());
  }, [dispatch]);

  return (
    <>
      <div className="--flex-center --cursor-pointer --ml2 --flex-gap --f-warp">
        <div className="test --bg-1">
          <div>
            <div className="--text-md">Donation</div>
            <div className="--text-sm">Total</div>
          </div>
          <div className="--text-md --mt">₹{totalAmount?.total}</div>
        </div>
        <div className="test --bg-2">
          <div>
            <div className="--text-md">Volunteers</div>
            <div className="--text">Total</div>
          </div>
          <div className="--text-md --mt">{volLen?.volLen}</div>
        </div>
        <div className="test --bg-3">
          <div>
            <div className="--text-md">Suscribers</div>
            <div className="--text">Total</div>
          </div>
          <div className="--text-md --mt">{sucLen?.sucLen}</div>
        </div>
        <div className="test --bg-4">
          <div>
            <div className="--text-20">Total Joined</div>
            <div className="--text">Total</div>
          </div>
          <div className="--text-md --mt">{joinLen?.joinLen}</div>
        </div>
      </div>
      <div className="--mt3 --center-all">
        <div className="chart">
          <ShowChart />
        </div>
      </div>
    </>
  );
};

export default DashBoard;
