import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const backendUserUrl = "https://root.missionpahal.org/api/users/";
export const backendUrl = "https://root.missionpahal.org/";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = "";
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export const shortenText = (text, n) => {
  let len = text.length;
  if (len > n) {
    const shortedText = text.substring(0, n);
    return shortedText;
  }
  return text;
};

export const expendText = async (text) => {
  let expendedText;
  if (text.length > 0) {
    expendedText = await text.split("\n").join("<br />");
  }
  return expendedText;
};

// Validate Email
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
