import React, { useEffect, useState } from "react";
import RepersentProject from "./RepersentProject";
import "./Project.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../redux/feattures/auth/authSlice";

const Project = () => {
  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.auth);
  const [Project, setProject] = useState("");

  useEffect(() => {
    if (typeof projects !== "undefined" && projects !== null) {
      setProject(Object.values(projects));
    }
  }, [setProject, projects]);

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const Projects = [...Project];
  return (
    <div>
      <div className="--m3 --p1x">
        <h1 className="--text-center our-initiative">
          <u>
            <span>Our</span>{" "}
            <span style={{ color: "#BD3D76" }} className="--just">
              Initiative
            </span>
          </u>
        </h1>
        {Projects.splice(0, 2).map((item, i) => (
          <RepersentProject key={item.id} details={item} i={i} />
        ))}
      </div>
    </div>
  );
};

export default Project;
