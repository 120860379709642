import { useEffect, useState } from "react";
import styles from "./auth.module.scss";
// import loginImage from "../../assets/login.png";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../utils/utils";
import { toast } from "react-toastify";
// import Loader from "../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RESET_AUTH, login } from "../redux/feattures/auth/authSlice";
import Card from "./Card";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const jwt = localStorage.getItem("AdminVerifivationToken");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isSuccess } = useSelector((state) => state.auth);

  const loginUser = async (e) => {
    e.preventDefault();
    // console.log(name, email, password, cPassword)
    if (!email || !password) {
      return toast.error("All fields are required");
    }

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }

    const userData = {
      email,
      password,
    };
    // console.log(userData);
    await dispatch(login(userData));
    window.location.reload();
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn && jwt !== null) {
      navigate("/admin/dashboard/");
    }

    dispatch(RESET_AUTH());
  }, [isSuccess, isLoggedIn, jwt, navigate, dispatch]);

  return (
    <>
      {/* {isLoading && <Loader />} */}

      <section className={`container ${styles.auth}`}>
        <div className={styles.img}>
          {/* <img
            src="https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg"
            alt="Login"
            width={400}
            height={300}
          /> */}
        </div>
        <Card>
          <div className={styles.form}>
            <h2>Login</h2>
            <form onSubmit={loginUser}>
              <input
                type="text"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                required
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="--btn --btn-primary --btn-block">
                Login
              </button>
            </form>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Login;
