import { api } from "../apiConfig";

const path = window.location.pathname.split("/");
const len = path.length;
const extatId = path[len - 1];

const id = extatId.replace("%20", " ");
// console.log(id);

// Login User
const login = async (userData) => {
  const response = await api.post("/api/admin/loginAdmin", userData);
  localStorage.setItem("AdminVerifivationToken", response.data.token);
  return response.data;
};

// Logout User
const logout = async () => {
  localStorage.setItem("AdminVerifivationToken", "");
};

// Get login status
const getLoginStatus = async () => {
  const response = await api.get("/api/admin/getLoginStatus");
  return response.data;
};

// Create Event
const createEvent = async (eventData) => {
  const response = await api.post(
    "/admin/adminControllerRoute/createEvent",
    eventData
  );
  return response.data;
};

// Get All Events
const getEvents = async () => {
  const response = await api.get("/api/public/getAllEvents");
  return response.data;
};

// Get Event By Id
const getEvent = async () => {
  const response = await api.get("/api/public/getEventById/" + id);
  return response.data;
};

// Update Event By Id
const updateEvent = async (eventData) => {
  const response = await api.patch(
    "/admin/adminControllerRoute/updateEvent/" + id,
    eventData
  );
  return response.data;
};

// Delete Event By Id
const deleteEvent = async (eventId) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteEvent/" + eventId
  );
  return response.data;
};

// Create Slider
const createSlider = async (sliderData) => {
  const response = await api.post(
    "/admin/adminControllerRoute/createSlider",
    sliderData
  );
  return response.data;
};

// Get All Events
const getAllSlider = async () => {
  const response = await api.get("/api/public/getAllSliders");
  return response.data;
};

// Get Slider By Id
const getSliderById = async () => {
  const response = await api.get("/api/public/getSliderById/" + id);
  return response.data;
};

// Update slider By Id
const updateSlider = async (sliderData) => {
  const response = await api.patch(
    "/admin/adminControllerRoute/updateSlider/" + id,
    sliderData
  );
  return response.data;
};

// Delete slider By Id
const deleteSlider = async (sliderId) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteSlider/" + sliderId
  );
  return response.data;
};

// Create Project
const createProject = async (sprojectData) => {
  const response = await api.post(
    "/admin/adminControllerRoute/createProject",
    sprojectData
  );
  return response.data;
};

// Get All Projects
const getAllProjects = async () => {
  const response = await api.get("/api/public/getAllProjects");
  return response.data;
};

// Get Project By Id
const getProject = async () => {
  const response = await api.get("/api/public/getProjectById/" + id);
  return response.data;
};

// Update Project By Id
const updateProject = async (projectData) => {
  const response = await api.patch(
    "/admin/adminControllerRoute/updateProject/" + id,
    projectData
  );
  return response.data;
};

// Delete Project By Id
const deleteProject = async (projectId) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteProject/" + projectId
  );
  return response.data;
};

// Create Testimonial
const createTestimonial = async (testimonialData) => {
  const response = await api.post(
    "/admin/adminControllerRoute/createTestimonial",
    testimonialData
  );
  return response.data;
};

// Get All Testimonial
const getAllTestimonial = async () => {
  const response = await api.get("/api/public/getAllTestimonials");
  return response.data;
};

// Get Testimonial By Id
const getTestimonialById = async () => {
  const response = await api.get("/api/public/getTestimonialById/" + id);
  return response.data;
};

// Update Testimonial By Id
const updateTestimonial = async (testimonialData) => {
  const response = await api.patch(
    "/admin/adminControllerRoute/updateestimonial/" + id,
    testimonialData
  );
  return response.data;
};

// Delete Testimonial By Id
const deleteTestimonial = async (testimonialId) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteestimonial/" + testimonialId
  );
  return response.data;
};

// Create Testimonial
const createGallery = async (imgData) => {
  const response = await api.post(
    "/admin/adminControllerRoute/createGalleryImage",
    imgData
  );
  return response.data;
};

// Get All Testimonial
const getAllGallery = async () => {
  const response = await api.get("/api/public/getAllGallery");
  return response.data;
};

// Delete Testimonial By Id
const deleteGallery = async (imgId) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteGalleryImage/" + imgId
  );
  return response.data;
};

// Join Us
const joinUsUser = (userData) => {
  const response = api.post("/api/public/joinUsUser", userData);
  return response.data;
};

// Get All Volunteers
const getAllJoinedUser = async () => {
  const response = await api.get(
    "/admin/adminControllerRoute/getAllJoinedUser"
  );
  return response.data;
};

// Register Volunteer
const register = (userData) => {
  const response = api.post("/api/public/registerVolunteer", userData);
  return response.data;
};

// Get All Volunteers
const getAllVolunteer = async () => {
  const response = await api.get("/admin/adminControllerRoute/getAllVolunteer");
  return response.data;
};

// Get Volunteer By Id
const getVolunteerById = async () => {
  const response = await api.get(
    "/admin/adminControllerRoute/getVolunteerById/" + id
  );
  return response.data;
};

// Update Volunteer By Id
const updateVolunteer = async (volunteerData) => {
  const response = await api.patch(
    "/admin/adminControllerRoute/updateVolunteer/" + id,
    volunteerData
  );
  return response.data;
};

// Delete Volunteer By Id
const deleteVolunteer = async (Id) => {
  const response = await api.delete(
    "/admin/adminControllerRoute/deleteVolunteer/" + Id
  );
  return response.data;
};

// paymetDataById
const getPaymentById = async () => {
  const response = await api.get("api/payment/paymetDataById/" + id);
  return response.data;
};

// getPayments
const getPayments = async () => {
  const response = await api.get("/admin/adminControllerRoute/getPayments");
  return response.data;
};

// getSubscribers
const getAllSuscribers = async () => {
  const response = await api.get("/admin/adminControllerRoute/getSubscribers");
  return response.data;
};


// getGraphData
const getGraphData = async () => {
  const response = await api.get("/admin/adminControllerRoute/getGraphData");
  return response.data;
};

// Get Dash Board Details
const getTotalAmount = async () => {
  const response = await api.get("/admin/adminControllerRoute/getTotalAmount");
  return response.data;
};

const getVolunteerCount = async () => {
  const response = await api.get(
    "/admin/adminControllerRoute/getVolunteerCount"
  );
  return response.data;
};

const getSubscribeCount = async () => {
  const response = await api.get(
    "/admin/adminControllerRoute/getSubscribeCount"
  );
  return response.data;
};

const getJoinUsCount = async () => {
  const response = await api.get("/admin/adminControllerRoute/getJoinUsCount");
  return response.data;
};

const authService = {
  login,
  logout,
  getLoginStatus,
  createEvent,
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  createSlider,
  getAllSlider,
  getSliderById,
  updateSlider,
  deleteSlider,
  createProject,
  getAllProjects,
  getProject,
  updateProject,
  deleteProject,
  createTestimonial,
  getAllTestimonial,
  getTestimonialById,
  updateTestimonial,
  deleteTestimonial,
  createGallery,
  getAllGallery,
  deleteGallery,
  joinUsUser,
  getAllJoinedUser,
  register,
  getAllVolunteer,
  getVolunteerById,
  updateVolunteer,
  deleteVolunteer,
  getPaymentById,
  getPayments,
  getAllSuscribers,
  getGraphData,
  getTotalAmount,
  getVolunteerCount,
  getSubscribeCount,
  getJoinUsCount,
};

export default authService;
