/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./Chart.css";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "../../../redux/feattures/auth/authSlice";

const ShowChart = () => {
  const dispatch = useDispatch();
  const { paymentGraph } = useSelector((state) => state.auth);

  let result = Object.keys(paymentGraph).map(function (key) {
    // Using Number() to convert key to number type
    // Using obj[key] to retrieve key value
    return [key, paymentGraph[key]];
  });

  useEffect(() => {
    dispatch(getGraphData());
  }, [dispatch]);

  return (
    <>
      <div className="--flex-center --f-warp">
        {typeof result !== "undefined" &&
          result.map((data, i) => (
            <div key={i} className="chart-container">
              <div className="amount">{data[1]}</div>
              <div className="month">{data[0]}</div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ShowChart;
