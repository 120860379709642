import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Testimonials.scss";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
// import tsImg from "../assets/group_1.png";
// import rahul from "../assets/Rahul.jpg";
// import img1 from "../assets/img1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAllTestimonial } from "../../redux/feattures/auth/authSlice";
import { backendUrl } from "../../utils/utils";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  650: { items: 2 },
  1024: { items: 2 },
};
const TestmonialDetails = () => {
  const dispatch = useDispatch();

  const { testimonials } = useSelector((state) => state.auth);
  const [testimonial, setTestimonials] = useState("");

  useEffect(() => {
    if (typeof testimonials !== "undefined" && testimonials !== null) {
      setTestimonials(Object.values(testimonials));
    }
  }, [setTestimonials, testimonials]);

  useEffect(() => {
    dispatch(getAllTestimonial());
  }, [dispatch]);
  const backUrl = backendUrl;
  const Testimonials = [...testimonial];
  const items = Testimonials.map((data) => (
    <div
      key={data.id}
      className="item --flex-row --card-testomonial --card --pxy --text"
      data-value={data.id}
    >
      <div className="--flex-row ">
        <div sx={{ width: "150px" }}>
          <img
            height="170"
            width="150"
            src={`${backUrl}${data.image}`}
            alt=""
          />
        </div>
        <div className="--px2 --my2 --text-center">
          <h4>{data.title}</h4>
          {data.description}
        </div>
      </div>
    </div>
  ));
  const renderNextButton = () => {
    return (
      <div style={{ position: "absolute", right: "0", top: "80px" }}>
        <AiOutlineArrowRight className="--arrow --next --top" />
      </div>
    );
  };
  const renderPrevButton = () => {
    return (
      <div style={{ position: "absolute", left: "0", top: "80px" }}>
        <AiOutlineArrowLeft className="--arrow --prev --top" />
      </div>
    );
  };
  // 8789860534
  return (
    <section className="--mt5">
      {Testimonials.length > 0 && (
        <AliceCarousel
          items={items}
          responsive={responsive}
          paddingLeft={50}
          paddingRight={50}
          controlsStrategy="responsive"
          autoPlay={true}
          autoPlayInterval={4000}
          infinite={true}
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
        />
      )}
    </section>
  );
};

export default TestmonialDetails;
