/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import styles from "./Navbar.module.scss";
import { FaTimes } from "react-icons/fa";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import logoImg from "../assets/logo2.png";
import facebook from "../assets/facebook.png";
import i from "../assets/i.png";
import twitter from "../assets/twitter.png";
import whatsapp from "../assets/whatsapp.png";

export const logo = (
  <div className={styles.logo}>
    <Link to="/">
      <img src={logoImg} height={"60px"} alt="Mission Pahal" />
      {/* <div>
        Mission <span>Pahal</span>
      </div> */}
    </Link>
  </div>
);

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPage, setScrollPage] = useState(false);

  const fixNavbar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", fixNavbar);

  const [isOpen, setIsOpen] = useState(false); // State for dropdown visibility

  const handleHover = (e) => {
    if (!isOpen) setIsOpen(!isOpen); // Open dropdown on hover if currently closed
  };

  const handleLeave = () => {
    if (isOpen) setIsOpen(!isOpen); // Close dropdown on mouse leave if currently open
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const hideMenu = () => {
    setShowMenu(false);
    setIsOpen(false);
  };

  const handleAllleave = () => {
    handleLeave();
  };

  return (
    <header
      onMouseLeave={handleAllleave}
      className={scrollPage ? `${styles.fixed}` : null}
    >
      <div className={styles.header}>
        {logo}
        <nav
          className={
            showMenu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}`
          }
        >
          <div
            className={
              showMenu
                ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}`
                : `${styles["nav-wrapper"]}`
            }
            onClick={hideMenu}
          ></div>
          <ul>
            <li
              onClick={() => setShowMenu(false)}
              className={styles["logo-mobile"]}
            >
              {logo}
              <FaTimes size={22} color="#fff" onClick={hideMenu} />
            </li>
            <li>
              <NavLink
                to="/"
                onClick={() => setShowMenu(false)}
                onMouseEnter={handleLeave}
                className={activeLink}
              >
                Home
              </NavLink>
            </li>

            <li>
              <div
                onClick={handleClick}
                onMouseEnter={handleHover}
                className="dropdown-button"
              >
                About Us
                {isOpen ? (
                  <i className="bi bi-chevron-compact-up --pl"></i>
                ) : (
                  <i className="bi bi-chevron-compact-down --pl"></i>
                )}
              </div>
              {isOpen && ( // Render dropdown content only if open
                <div className="dropdown" onMouseLeave={handleLeave}>
                  <div>
                    <NavLink
                      onClick={() => setShowMenu(false)}
                      className={activeLink}
                      to="/aboutUs"
                    >
                      About Us
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      onClick={() => setShowMenu(false)}
                      className={activeLink}
                      to="/our-team"
                    >
                      Our Team
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      onClick={() => setShowMenu(false)}
                      className={activeLink}
                      to="/join-us"
                    >
                      Join Us
                    </NavLink>
                  </div>
                </div>
              )}
            </li>

            <li>
              <NavLink
                onMouseEnter={handleLeave}
                to="/projects"
                className={activeLink}
                onClick={() => setShowMenu(false)}
              >
                Project
              </NavLink>
            </li>

            <li>
              <NavLink
                onMouseEnter={handleLeave}
                to="/events"
                className={activeLink}
                onClick={() => setShowMenu(false)}
              >
                Events
              </NavLink>
            </li>

            <li>
              <NavLink
                onMouseEnter={handleLeave}
                to="/gallery"
                className={activeLink}
                onClick={() => setShowMenu(false)}
              >
                Gallery
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/blogs" className={activeLink}>
                Blogs
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact_us" className={activeLink}>
                Contact Us
              </NavLink>
            </li> */}
          </ul>
          <div onClick={handleLeave} className={styles["header-right"]}>
            <span className={styles.links}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100079154242688&mibextid=ZbWKwL"
              >
                {/* <i className="bi bi-facebook"></i> */}
                <img height={"30px"} src={facebook} alt={facebook} />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/missionpahal/"
              >
                <img height={"30px"} src={i} alt={i} />
                {/* <i className="bi bi-instagram"></i> */}
              </a>
              <Link
                // rel="noreferrer"
                // target="_blank"
                href="/"
              >
                <img height={"30px"} src={twitter} alt={twitter} />
                {/* <i className="bi bi-instagram"></i> */}
              </Link>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/message/63AYDPVB3B66F1"
              >
                <img height={"30px"} src={whatsapp} alt={whatsapp} />
                {/* <i className="bi bi-instagram"></i> */}
              </a>
              <Link onClick={() => setShowMenu(false)} to="/donation">
                <button>Donate</button>
              </Link>
            </span>
          </div>
        </nav>
        <div className={styles["menu-icon"]}>
          <HiOutlineMenuAlt3 size={28} onClick={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
