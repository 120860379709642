import React from "react";
import { Route, Routes } from "react-router-dom";
// import Sidebar from "../Admin/component/Sidebar";
// import DashBoard from "../Admin/pages/DashBoard";
import AdminRoute from "../AdminRoute/AdminRoute";
// import PageNotFound from "../PageNotFound";

import Login from "../auth/Login";

const AdminROutes = () => {
  return (
    <>
      <Routes>
        <Route path="/admin/verification/login" element={<Login />} />

        <Route path="/dashboard/*" element={<AdminRoute />} />
      </Routes>
    </>
  );
};

export default AdminROutes;
