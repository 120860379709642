import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "../Admin/component/Sidebar";
import DashBoard from "../Admin/pages/DashBoard";
import Payment from "../Admin/pages/Payment/Payment";
import Suscribers from "../Admin/pages/Suscriber/Suscribers";
import AdminEvents from "../Admin/pages/Event/AdminEvents";
import AdminTestimonials from "../Admin/pages/Testimonials/AdminTestimonials";
import NewTestimonial from "../Admin/pages/Testimonials/AddTestimonial";
import AddVolunteer from "../Admin/pages/Volunteer/AddVolunteer";
import { ShowonLogOut, ShowonLogin } from "../hiddenLink/hiddenLink";
import PageNotFound from "../PageNotFound";
import UpdateEvents from "../Admin/pages/Event/UpdateEvents";
import NewEvent from "../Admin/pages/Event/NewEvent";
import UpdateTestimonial from "../Admin/pages/Testimonials/UpdateTestimonial";
import AdminSlider from "../Admin/pages/Slider/AdminSlider";
import AddSlider from "../Admin/pages/Slider/AddSlider";
import UpdateSlider from "../Admin/pages/Slider/UpdateSlider";
import AdminGallery from "../Admin/pages/Gallery/AdminGallery";
import AddGallery from "../Admin/pages/Gallery/AddGallery";
import AdminProjects from "../Admin/pages/Projects/AdminProjects";
import NewProject from "../Admin/pages/Projects/NewProject";
import UpdateProject from "../Admin/pages/Projects/UpdateProject";
import Volunteer from "../Admin/pages/Volunteer/Volunteer";
import UpdateVolenter from "../Admin/pages/Volunteer/UpdateVolenteer";
import Joined from "../Admin/pages/joined/Joined";
import { useSelector } from "react-redux";

const AdminRoute = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      isLoggedIn?.message === "invalid token" ||
      isLoggedIn?.message === "jwt malformed"
    ) {
      navigate("/admin/admin/verification/login");
    }
  }, [isLoggedIn, navigate]);
  return (
    <>
      <ShowonLogin>
        <section>
          <div className="grid-container">
            <Sidebar className="item2" />
            <div className="item5 --text --mt3">
              <Routes>
                <Route path="/" element={<DashBoard />} />

                <Route path="/payments" element={<Payment />} />
                <Route path="/volunteer" element={<Volunteer />} />
                <Route
                  path="/volunteer/update-volenter/:id"
                  element={<UpdateVolenter />}
                />

                <Route
                  path="/volunteer/add-volunteer"
                  element={<AddVolunteer />}
                />
                <Route path="/suscribers" element={<Suscribers />} />

                <Route path="/joined" element={<Joined />} />
                <Route path="/events" element={<AdminEvents />} />
                <Route path="/slider" element={<AdminSlider />} />
                <Route path="/slider/add-slider" element={<AddSlider />} />
                <Route
                  path="/slider/edit-slider/:id"
                  element={<UpdateSlider />}
                />
                <Route path="/events/add-event" element={<NewEvent />} />
                <Route
                  path="/events/edit-event/:id"
                  element={<UpdateEvents />}
                />
                <Route path="/testimonials" element={<AdminTestimonials />} />
                <Route
                  path="testimonials/edit-testimonial/:id"
                  element={<UpdateTestimonial />}
                />
                <Route
                  path="testimonials/add-testimonials"
                  element={<NewTestimonial />}
                />
                <Route path="/projects" element={<AdminProjects />} />
                <Route path="projects/add-project" element={<NewProject />} />
                <Route
                  path="projects/edit-project/:id"
                  element={<UpdateProject />}
                />
                <Route path="gallery/" element={<AdminGallery />} />
                <Route path="gallery/add-gallery/" element={<AddGallery />} />
              </Routes>
            </div>
          </div>
        </section>
      </ShowonLogin>
      <ShowonLogOut>
        <PageNotFound />
      </ShowonLogOut>
    </>
  );
};

export default AdminRoute;
