import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllSuscribers } from "../../../redux/feattures/auth/authSlice";
const Suscribers = () => {
  const dispatch = useDispatch();

  const { suscribers } = useSelector((state) => state.auth);
  const [paymentss, setPaymentss] = useState("");

  useEffect(() => {
    if (typeof suscribers !== "undefined" && suscribers !== null) {
      setPaymentss(Object.values(suscribers));
    }
  }, [setPaymentss, suscribers]);
  useEffect(() => {
    dispatch(getAllSuscribers());
  }, [dispatch]);

  const data = [...paymentss];

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "id",
      selector: (row) => row.id,
    },

    {
      name: "email",
      selector: (row) => row.email,
    },
  ];

  const handleOnExport = () => {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);
    const createdDate = new Date();
    console.log(createdDate.getDate());
    const date =
      createdDate.getDate() +
      "-" +
      createdDate.getMonth() +
      1 +
      "-" +
      createdDate.getFullYear();
    console.log(date);
    /* Add Worksheet to workbook and return */
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `Suscribers_${date}.xlsx`);
  };
  
  return (
    <div className="--flex-center --cursor-pointer --ml2 --mt3 --flex-gap --f-warp">
      <h1>Suscribers</h1>
      <div className="--w-90">
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="495px"
          highlightOnHover
          actions={
            <button onClick={handleOnExport} className="--btn --btn-primary">
              Export
            </button>
          }
        />
      </div>
    </div>
  );
};

export default Suscribers;
