import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getEvents } from "../../redux/feattures/auth/authSlice";
import { backendUrl, shortenText } from "../../utils/utils";
import Banner from "../../components/Custom/Banner";
import imgUrl from "../../components/assets/event.png";

const EventsPage = () => {
  const dispatch = useDispatch();

  const { events } = useSelector((state) => state.auth);
  const [event, setEvent] = useState("");

  useEffect(() => {
    if (typeof events !== "undefined" && events !== null) {
      setEvent(Object.values(events));
    }
  }, [setEvent, events]);

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const Events = [...event];
  const backUrl = backendUrl;

  let heading = "Events";

  return (
    <section>
      <Banner imgUrl={imgUrl} heading={heading} />
      <div className="--px2 --my2 --flex-center --flex-gap --f-warp">
        {Events.map((item, i) => (
          <div className="--mb" key={item.id}>
            <>
              <div id={item._id} className="--m2">
                {i % 2 !== 0 ? (
                  <div className="--grid-50 --wrap-rev">
                    <div className="--text-justify">
                      <h1 className="--font-k">{item.title}</h1>
                      <div>
                        <p className="--p-text">
                          {shortenText(item.description, 800)
                            .split("\n")
                            .map((item, id) => (
                              <span key={id}>
                                {item}
                                <br />
                              </span>
                            ))}
                          <Link
                            className="--color-primary"
                            to={`/events/${item.id}`}
                          >
                            {" "}
                            read more...
                          </Link>
                        </p>
                      </div>
                    </div>
                    <img
                      className="object --mt"
                      // height={350}
                      src={`${backUrl}${item.image}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="--grid-50">
                    <div className="--flex-center">
                      <img
                        className="object --mt"
                        // height={350}
                        src={`${backUrl}${item.image}`}
                        alt=""
                      />
                    </div>
                    <div className="--text-justify">
                      <h1 className="--font-k">{item.title}</h1>
                      <div>
                        <p className="--p-text">
                          {shortenText(item.description, 800)
                            .split("\n")
                            .map((item, id) => (
                              <span key={id}>
                                {item}
                                <br />
                              </span>
                            ))}
                          <Link
                            className="--color-primary"
                            to={`/events/${item.id}`}
                          >
                            {" "}
                            read more...
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventsPage;
