import React from "react";

const PageNotFound = () => {
  return (
    <div className="--90vh --center-all">
      <h1>404 page not found</h1>
    </div>
  );
};

export default PageNotFound;
