import React, { useState } from "react";
import DonationCard from "../../components/Custom/DonationCard";
import { Link } from "react-router-dom";
import "./DonationForm.css";
import WhyDonate from "./WhyDonate";
import { backendUrl, useScrollLock } from "../../utils/utils";

import img1 from "../../components/assets/img1.png";
import img2 from "../../components/assets/img2.png";
import img3 from "../../components/assets/img3.png";
import img4 from "../../components/assets/img4.jpg";
import imgUrl from "../../components/assets/banner2.png";
import { api } from "../../redux/feattures/apiConfig";

const DonationPage = () => {
  // let imgUrl =
  //   "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg";
  let heading = "Donate Now";

  const [modal, setModal] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const [amount, setAmount] = useState(0);
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");

  const handleOnChange = (e) => {
    setAmount(e.target.value);
  };

  const handleClick = (amt) => {
    // console.log(amt);
    setAmount(amt);
    openModal(true);
  };

  const openModal = (amt) => {
    if (amt === 0) {
      setAmount(1000);
    }

    setModal(true);
    lockScroll();
  };
  const closeModal = () => {
    setModal(false);
    unlockScroll();
  };

  const checkoutHandler = async (e) => {
    e.preventDefault();
    const {
      data: { KEY_ID },
    } = await api.get(`${backendUrl}api/payment/getKey`);
    const {
      data: { order },
    } = await api.post(`${backendUrl}api/payment/paymentId`, {
      amount,
    });
    // console.log(order);
    const number = "+91" + mobile;
    const options = {
      key: KEY_ID,
      amount: order.amount,
      currency: "INR",
      name: fullname,
      description: description,
      image: "",
      order_id: order.id,
      // callback_url: "http://localhost:5050/api/payment/verify",
      handler: async (response) => {
        try {
          const userData = {
            name: fullname,
            email: email,
            mobile: number,
            message: description,
            amount: amount,
          };
          const allData = { response, userData };
          const verifyUrl = `${backendUrl}api/payment/verify`;
          const { data } = await api.post(verifyUrl, allData);
          // console.log(data);
          if (data.message === "Payment Varifyed Successfully") {
            window.location.href = `/donation/success/${data.paymentData.razorpay_order_id}`;
          } else {
            throw new Error("Something went wrong!");
          }
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: fullname,
        email: email,
        contact: number,
      },
      method: {
        netbanking: true,
        card: true,
        wallet: false,
        upi: true,
        paylater: false,
      },
      notes: {
        description: description,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <section>
      <div className="banner --center-all">
        <div
          className="--center-all heading "
          // style={{ color: "#000", fontSize: "3rem", zIndex: "50" }}
        >
          {heading}
        </div>
        <img height="300px" width="100%" src={imgUrl} alt="" />
      </div>
      <div className="--my2 --px3">
        <div className=" --flex-dir-row --f-warp --my2">
          <DonationCard
            donationAmt={1000}
            imgUrl={img1}
            handleClick={handleClick}
            content={"Contribute towards a child’s education for 2 Months"}
          />
          <DonationCard
            donationAmt={2000}
            imgUrl={img2}
            handleClick={handleClick}
            content={"Contribute towards a child’s education for 3 Months"}
          />
          <DonationCard
            donationAmt={3000}
            imgUrl={img3}
            handleClick={handleClick}
            content={"Contribute towards a child’s education for 6 Months"}
          />
          <DonationCard
            donationAmt={4000}
            imgUrl={img4}
            handleClick={handleClick}
            content={"Contribute towards a child’s education for 12 Months"}
          />
        </div>
        <div className="--my4-c --custome-input  --flex-center ">
          <input
            className="--mr --pxy1 --mb2"
            placeholder="Donate amount of my choice"
            type="number"
            name="amount"
            onChange={handleOnChange}
            id="amount"
          />

          <Link
            onClick={() => openModal(amount)}
            className="--custom-card-button --text --mb2"
          >
            Donate
          </Link>
        </div>
      </div>
      {modal && (
        <>
          <div className="--center-all --p-a">
            <div className="formcard --card --z-50 --p-f-b  --btn-secondary">
              <div className="--text-20 --mt --hr --text-dark">
                <div className="--flex-start">Personal Information</div>
                <div onClick={closeModal} className="--flex-end -mt-2">
                  <i className="bi bi-x-lg"></i>
                </div>
              </div>
              <form
                onSubmit={checkoutHandler}
                className="--flex-start --flex-dir-column --text-dark --px1_5 --form-control"
              >
                <label htmlFor="name">Full Name</label>
                <input
                  onChange={(e) => setFullName(e.target.value)}
                  className="--width-100"
                  type="text"
                  name="name"
                  value={fullname}
                  id="name"
                />
                <label htmlFor="email">Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="--width-100"
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                />
                <label htmlFor="phone">Phone (+91)</label>
                <input
                  onChange={(e) => setMobile(e.target.value)}
                  className="--width-100"
                  type="tel"
                  name="number"
                  value={mobile}
                  id="number"
                />
                <label htmlFor="showAmount">Donation Amount</label>
                <input
                  className="--width-100"
                  type="number"
                  name="showAmount"
                  id="showAmount"
                  value={amount}
                  readOnly
                />
                <label htmlFor="description">Any message</label>
                <input
                  onChange={(e) => setDescription(e.target.value)}
                  className="--width-100"
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                />
                <button
                  type="submit"
                  className="--btn --btn-lg --btn-block --btn-success"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div onClick={closeModal} className="blur-bg"></div>
        </>
      )}
      <WhyDonate />
    </section>
  );
};

export default DonationPage;
