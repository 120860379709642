import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteGallery,
  getAllGallery,
} from "../../../redux/feattures/auth/authSlice";
import { backendUrl, useScrollLock } from "../../../utils/utils";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const AdminGallery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { gallery } = useSelector((state) => state.auth);
  //   console.log(gallery);
  //   console.log(typeof gallery);

  //   console.log(sliders);
  //   let arr = Object.entries(gallery);
  //   let arr = [...Object.values(gallery)];
  //   console.log(arr);

  const [imge, setImag] = useState("");

  useEffect(() => {
    if (typeof gallery !== "undefined" && gallery !== null) {
      setImag(Object.values(gallery));
    }
  }, [setImag, gallery]);
  //   console.log(imge);

  useEffect(() => {
    dispatch(getAllGallery());
  }, [dispatch]);

  const handleDelete = async (id) => {
    if (window.confirm("Confirme !")) {
      await dispatch(deleteGallery(id));
      window.location.reload();
    }
  };

  //   const images = [
  //     "https://picsum.photos/200/300?image=1050",
  //     "https://picsum.photos/300/300?image=206",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  //     "https://picsum.photos/200/300?image=1050",
  //     "https://picsum.photos/300/300?image=206",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  //     "https://picsum.photos/200/300?image=1050",
  //     "https://picsum.photos/300/300?image=206",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b2.jpg",
  //     "https://missionpahal.org/wp-content/themes/mp-theme/assets/images/b1.jpg",
  //   ];
  const images = [...imge];
  // console.log(images);
  const { lockScroll, unlockScroll } = useScrollLock();

  const [imgViewer, setViewer] = useState(false);

  const closeViewer = () => {
    setViewer(false);
    unlockScroll();
  };

  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setData({ img, i });
    setViewer(true);
    lockScroll();
  };

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      if (i < images.length - 1) {
        setData({ img: images[i + 1].image, i: i + 1 });
      } else {
        i = 0;
        setData({ img: images[i].image, i: i });
      }
    }
    if (action === "prev-img") {
      if (i > 0) {
        setData({ img: images[i - 1].image, i: i - 1 });
      } else {
        i = images.length;
        setData({ img: images[i - 1].image, i: i - 1 });
      }
    }
  };
  const backUrl = backendUrl;
  return (
    <>
      {imgViewer && (
        <div style={{ position: "absolute", top: "0" }} className="--w-80">
          <div className="--center-all --cursor-pointer --p-a">
            {/* <div className="--center-all"></div> */}
            <div
              style={{
                width: "70%",
                top: " 4rem",
                position: "fixed",
                zIndex: 50,
              }}
              className="--card --my --center-all"
            >
              <div className="img-box">
                <img
                  style={{
                    objectFit: "cover",
                    maxHeight: "558px",
                    maxWidth: "1008px",
                  }}
                  src={`${backUrl}${data.img}`}
                  alt={data.i}
                />
              </div>
              <AiOutlineArrowLeft
                style={{
                  background: "transparent",
                  width: "2.5rem",
                  height: "5rem",
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  left: "5rem",
                  color: "#fff",
                  zIndex: 5,
                }}
                onClick={() => imgAction("prev-img")}
              />
              <AiOutlineArrowRight
                style={{
                  background: "trnaparent",
                  width: "2.5rem",
                  height: "5rem",
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  right: "5rem",
                  color: "#fff",
                  zIndex: 5,
                }}
                onClick={() => imgAction("next-img")}
              />
              <div
                onClick={closeViewer}
                style={{
                  position: "absolute",
                  top: "0rem",
                  right: "0rem",
                  fontSize: "2rem",
                  fontWeight: "900",
                  background: "#fff",
                  padding: ".3rem",
                  cursor: "pointer",
                  zIndex: 20,
                }}
              >
                <i className="bi bi-x-lg"></i>
              </div>
            </div>
          </div>
          <div
            onClick={closeViewer}
            style={{
              position: "fixed",
              height: "100vh",
              width: " 100%",
              backgroundColor: "#666060cb",
              top: "0",
              left: "0",
            }}
          ></div>
        </div>
      )}
      <div className="add-events --width-100 --hr">
        <h1 className="--text-center --mb2 --p">Admin Gallery</h1>
        <button
          onClick={() => navigate("/admin/dashboard/gallery/add-gallery")}
        >
          + Add Image
        </button>
      </div>
      <section>
        <div className="--p1x">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry columnsCount={3} gutter="20px">
              {images.map((item, i) => (
                <div key={item.id}>
                  <img
                    alt={item.image}
                    key={item.id}
                    src={`${backUrl}${item.image}`}
                    style={{
                      width: "100%",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => viewImage(item.image, i)}
                  />
                  <div>
                    <div className="--flex-center --mt">
                      <Link
                        onClick={() => handleDelete(item.id)}
                        className="--btn --bg-danger --w-90"
                      >
                        Delete
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>

        <br />
        <br />
      </section>
    </>
  );
};

export default AdminGallery;
