import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getEvents } from "../../redux/feattures/auth/authSlice";
import { backendUrl, shortenText } from "../../utils/utils";
import "./Event.css";

const Event = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { events } = useSelector((state) => state.auth);
  const [event, setEvent] = useState("");

  useEffect(() => {
    if (typeof events !== "undefined" && events !== null) {
      setEvent(Object.values(events));
    }
  }, [setEvent, events]);

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const Events = [...event];

  const backUrl = backendUrl;
  return (
    <>
      <div className="--m3 --p1x">
        <h1 onClick={() => navigate("/events")} className="--ml2 --cursor">
          Events
        </h1>
        <div className="--grid-25 --m2 --text-justify">
          {Events.splice(0, 3).map((event) => (
            <div
              key={event.id}
              onClick={() => navigate(`/events/${event.id}`)}
              className="--e-card --card --pxy2"
            >
              <img src={`${backUrl}${event.image}`} alt="" />
              <h3 className="--mt">{event.title}</h3>
              <p className="--mt --text-justify">
                {shortenText(event.description, 100)}
                <Link className="--color-primary" to={`/events/${event.id}`}>
                  {" "}
                  read more
                </Link>
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Event;
