import React from "react";

const Map = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14649.325506282665!2d85.2882442!3d23.3762264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e1c7a530f8d7%3A0xc17e0decc411ed4a!2sMission%20Pahal!5e0!3m2!1sen!2sin!4v1708435189802!5m2!1sen!2sin"
        width="100%"
        height="300"
        title="map"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
