import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../../../redux/feattures/auth/authSlice";

const Payment = () => {
  const dispatch = useDispatch();

  const { payments } = useSelector((state) => state.auth);
  const [paymentss, setPaymentss] = useState("");

  useEffect(() => {
    if (typeof payments !== "undefined" && payments !== null) {
      setPaymentss(Object.values(payments));
    }
  }, [setPaymentss, payments]);
  useEffect(() => {
    dispatch(getPayments());
  }, [dispatch]);

  const data = [...paymentss];

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
    },
    {
      name: "email",
      selector: (row) => row.email,
    },
    {
      name: "amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "razorpay_payment_id",
      selector: (row) => row.razorpay_payment_id,
    },
    {
      name: "razorpay_order_id",
      selector: (row) => row.razorpay_order_id,
    },
  ];

  const handleOnExport = () => {
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);
    const createdDate = new Date();
    console.log(createdDate.getDate());
    const date =
      createdDate.getDate() +
      "-" +
      createdDate.getMonth() +
      1 +
      "-" +
      createdDate.getFullYear();
    console.log(date);
    /* Add Worksheet to workbook and return */
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `Payments_${date}.xlsx`);
  };

  return (
    <div className="--flex-center --cursor-pointer --flex-gap --f-warp">
      <h1>Payments</h1>
      <div className="--w-90">
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="495px"
          highlightOnHover
          actions={
            <button onClick={handleOnExport} className="--btn --btn-primary">
              Export
            </button>
          }
        />
      </div>
    </div>
  );
};

export default Payment;
