import React from "react";
import Slider from "../../components/slider/Slider";

import "./Home.scss";
import Event from "../../components/events/Event";
import Project from "../../components/projects/Project";
import TestmonialDetails from "../../components/Testimonial/TestimonialDetails";
import Suscriber from "../../components/suscribers/Suscriber";
import Map from "../../components/Map/Map";
// import bannerImg from "../../components/assets/defaultBanner.png"

const HomePage = () => {
  return (
    <div>

          <Slider />
          <Project />
          <Event />
          <Suscriber />
          <TestmonialDetails />
          <Map />
  
    </div>
  );
};

export default HomePage;
