/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./VolunteerRegPage.scss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { register } from "../../redux/feattures/auth/authSlice";
import avtar from "../../components/assets/avtar.png";

const VolunteerRegPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState("");
  const [profession, setProfession] = useState("");
  const [intrest, setIntrest] = useState("");
  const [howKnow, setHowKnow] = useState("");
  const [gender, setGander] = useState("");
  const [experience, setExperience] = useState("");
  const [statement, setStatement] = useState(false);
  let [Availablity, setA] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const dispatch = useDispatch();

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // let Availablity = [];
  const handleAvailablityChanche = async (d) => {
    if (Availablity.some((i) => i === d)) {
      Availablity = Availablity.filter((item) => item !== d);
    } else {
      Availablity.push(d);
    }
  };

  const dataSetter = () => {
    setName("");
    setEmail("");
    setMobile("");
    setAddress("");
    setDOB("");
    setProfession("");
    setIntrest("");
    setHowKnow("");
    setGander("");
    setExperience("");
    setStatement(false);
    setA([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !email ||
      !mobile ||
      !address ||
      !dob ||
      !profession ||
      !intrest ||
      !howKnow ||
      !gender ||
      !experience
    ) {
      return toast.error("All fields are require");
    }
    if (statement === false) {
      toast.warning("Please Check the Satement box");
    }
    if (Availablity.length < 0) {
      toast.warning("Please select any day");
    }
    if (profileImage === null) {
      return toast.warning("Please select a profile image ");
    }

    const volunteerData = new FormData();
    volunteerData.append("name", name);
    volunteerData.append("email", email);
    volunteerData.append("mobile", mobile);
    volunteerData.append("address", address);
    volunteerData.append("profileImage", profileImage);
    volunteerData.append("dob", dob);
    volunteerData.append("profession", profession);
    volunteerData.append("intrest", intrest);
    volunteerData.append("howKnow", howKnow);
    volunteerData.append("gender", gender);
    volunteerData.append("experience", experience);
    volunteerData.append("statement", statement);
    volunteerData.append("availablity", Availablity);
    console.log(Object.fromEntries(volunteerData));
    await dispatch(register(volunteerData));
    // dataSetter();
  };

  return (
    <div className="">
      <h2 className="--mt3 --mb --text-center">Volunteer Registration Form</h2>
      <div className="--flex-center">
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "650px" }}
          className="--my2 --form-control --p2 --card"
        >
          <div className="--flex-center">
            <div className="profile-photo --center-all">
              <div>
                <img
                  src={imagePreview === null ? avtar : imagePreview}
                  onChange={(e) => console.log(e.target.value)}
                  alt="profile"
                />
                <div className="--px --mt">
                  <label htmlFor="profileImage">Change Photo:</label>
                  <br />
                  <input
                    type="file"
                    name="profileImage"
                    id="profileImage"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>

                {/* {imagePreview !== null && (
                  <div className="--center-all --mt">
                    <div className="--btn --btn-primary" onClick={savePhoto}>
                      Upload Image
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className="--px">
              <label htmlFor="name">Full Name</label> <br />
              <input
                className="--my w-270"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
              />
            </div>
            <div className="--px">
              <label htmlFor="email">Email</label>
              <br />
              <input
                className="--my w-270"
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="--px">
              <label htmlFor="phone">Phone (+91)</label>
              <br />
              <input
                className="--my w-270"
                type="tel"
                name="tel"
                id="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="--px">
              <label htmlFor="dateofbirth">Date Of Birth</label>
              <br />
              <input
                className="--my w-270"
                type="date"
                name="dateofbirth"
                value={dob}
                onChange={(e) => setDOB(e.target.value)}
                id="dateofbirth"
              />
            </div>
            <div className="--px">
              <label htmlFor="name">Location (In Ranchi)</label>
              <br />
              <input
                className="--my w-270"
                type="text"
                name="address"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="--px">
              <label htmlFor="name">Profession</label>
              <br />
              <input
                className="--my w-270"
                type="text"
                name="address"
                id="address"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>
            <div className="--px">
              <label htmlFor="skillandintrests">Skill or Intrests</label>
              <br />
              <select
                onChange={(e) => setIntrest(e.target.value)}
                name="skillandintrests"
                className="--my w-270"
              >
                <option value=""></option>
                <option value="Teaching">Teaching</option>
                <option value="Games">Games</option>
                <option value="Fundraising">Fundraising</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="--px">
              <label htmlFor="question">
                How do you know about Mission <br /> Pahal
              </label>
              <br />
              <select
                onChange={(e) => setHowKnow(e.target.value)}
                name="question"
                className="--my w-270"
              >
                <option value=""></option>
                <option value="Friends And Family">Friends And Family</option>
                <option value="Instagram">Instagram</option>
                <option value="YouTube">YouTube</option>
                <option value="Facebook">Facebook</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="--px">
              <label htmlFor="gender">Gender</label>

              <div className="w-270">
                <div className="--w-90">
                  <input
                    className="--mx"
                    type="radio"
                    name="gender"
                    id="male"
                    value={"Male"}
                    onChange={(e) => setGander(e.target.value)}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className="--w-90">
                  <input
                    className="--mx"
                    type="radio"
                    name="gender"
                    id="female"
                    value={"Female"}
                    onChange={(e) => setGander(e.target.value)}
                  />
                  <label htmlFor="female">Female</label>
                </div>
                <div className="--w-90">
                  <input
                    className="--mx"
                    type="radio"
                    name="gender"
                    id="prefernottosay"
                    onChange={(e) => setGander(e.target.value)}
                    value={"Prefer not to say"}
                  />
                  <label htmlFor="prefernottosay">Prefer not to say</label>
                </div>
              </div>
            </div>
            <div className="--px">
              <label htmlFor="experience">Previous Volunteer Experience</label>

              <div className="w-270">
                <div className="--w-90">
                  <input
                    className="--mx"
                    type="radio"
                    name="experience"
                    id="yes"
                    onChange={(e) => setExperience(e.target.value)}
                    value={"Yes"}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="--w-90">
                  <input
                    className="--mx"
                    type="radio"
                    name="experience"
                    id="no"
                    onChange={(e) => setExperience(e.target.value)}
                    value={"No"}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>
          </div>
          <div className="--pxy1">
            <label htmlFor="availablity">Availablity</label>
            <div className="--flex-row-wrap --w-90">
              {dayNames.map((day) => (
                <div className="--mx --flex-row" key={day}>
                  <input
                    type="checkbox"
                    className="--mr"
                    onChange={() => handleAvailablityChanche(day)}
                    value={day}
                    id={day}
                  />
                  <label htmlFor={day}> {day} </label>
                </div>
              ))}
            </div>
          </div>
          <div className="--px2y">
            <label htmlFor="commitmentstatement">Commitment Statement:</label>
            <div className="w-90">
              <input
                className="--mr"
                type="checkbox"
                name="statement"
                // onChange={(e) => setStatement(e.target.value)}
                onClick={() => {
                  setStatement(!statement);
                }}
                id="statement"
              />
              <label htmlFor="statement">
                I am committed to contributing my time and efforts to support
                Mission Pahal's initiatives
              </label>
            </div>
          </div>
          <div className="--py2">
            <button
              type="submit"
              className="--btn --btn-lg --btn-block --btn-primary "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VolunteerRegPage;
