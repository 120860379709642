import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentById } from "../../redux/feattures/auth/authSlice";
import "./payment.css"

import { jsPDF } from "jspdf";

const PaymentSuccess = () => {
  const dispatch = useDispatch();

  const userDetailsRef = useRef(null);

  const { getPaymentWithId } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getPaymentById());
  }, [dispatch]);
  // console.log(getPaymentWithId);

  const downloadPdf = async () => {
    // window.location.reload();
    const input = userDetailsRef.current;

    const doc = new jsPDF({ orientation: "portrait" });
    doc.html(input, {
      callback: function (doc) {
        doc.save("Mission_Pahal_payment.pdf");
      },
      html2canvas: { scale: 0.3 },
    });
  };
  return (
    <section>
      <div className="--center-all --90vh">
        <h1 className="--color-success p-s">Success</h1>
        <button onClick={downloadPdf} className="--btn --btn-success --cursor">
          Download Recipt
        </button>
      </div>

      {/* donerDetails */}

      <div className="--center-all" style={{ display: "none" }}>
        {getPaymentWithId !== null && (
          <div
            className="--flex-start --text-left"
            style={{
              width: "595px",
              height: "842px",
            }}
            id="detail"
            ref={userDetailsRef}
          >
            <div style={{ marginTop: "5rem", padding: "10px 5rem" }}>
              <div className="--text-center">
                {" "}
                <h1>MISSION PAHAL</h1>
              </div>
              <div
                style={{
                  fontSize: "2rem",
                  marginBottom: "2rem",
                  marginTop: "4rem",
                }}
                className="--width-100"
              >
                <b>Name : </b>
                {getPaymentWithId.name}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Email : </b>
                {getPaymentWithId.email}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Mobile No. : </b>
                {getPaymentWithId.mobile}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Amount : </b>
                {getPaymentWithId.amount}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Payment Id :</b>
                {getPaymentWithId.razorpay_payment_id}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Payment Date :</b>
                {getPaymentWithId.createdDate}
              </div>
              <div
                style={{ fontSize: "2rem", marginBottom: "2rem" }}
                className="--width-100"
              >
                <b>Message :</b>
                {getPaymentWithId.message}
              </div>

              <div style={{ marginTop: "6rem" }} className="--text-right">
                <h3>Thanks for donating </h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PaymentSuccess;
