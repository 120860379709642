import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../redux/feattures/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { backendUrl, shortenText } from "../../utils/utils";

const SingleProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projects } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const [Project, setProject] = useState("");

  const { id } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Projects = [...Project];

  const [singleProject, setSingleProject] = useState([]);
  //   const [otherProjects, setOtherProjects] = useState([]);
  const restProjects = Projects.filter((item) => item.id !== id);
  // console.log(restProjects);

  useEffect(() => {
    if (typeof projects !== "undefined" && projects !== null) {
      setProject(Object.values(projects));
    }
  }, [setProject, projects]);

  useEffect(() => {
    let p = Projects.find((item) => item.id === id);
    setSingleProject(p);
  }, [setSingleProject, Projects, id]);
  const backUrl = backendUrl;

  return (
    <>
      <div>
        {typeof singleProject !== "undefined" && (
          <div className="--width-100 --px3">
            <h1 className="--flex-start --my2 --font-k">
              {singleProject.title}
            </h1>
            <div className="--center-all">
              <div
                style={{ height: "400px", marginBottom: "3rem" }}
                className="--width-100"
              >
                <img
                  height="400"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    objectPosition: "top",
                  }}
                  src={`${backUrl}${singleProject.image}`}
                  alt=""
                />
              </div>
            </div>
            <div className="--mb">
              <p className="--mb --p-text">
                {singleProject.description &&
                  singleProject.description.length > 0 &&
                  singleProject.description.split("\n").map((item, id) => (
                    <span key={id}>
                      {item}
                      <br />
                    </span>
                  ))}
              </p>
            </div>
          </div>
        )}
        <br />
        <br />
        {restProjects.length > 0 && (
          <div className="--my2 --px3">
            <h1 className="--mt4 --just-j">Other Projects</h1>
            <div className="--grid-25">
              {restProjects && restProjects.length > 0 && (
                <>
                  {restProjects.splice(0, 3).map((data) => (
                    <>
                      <div
                        onClick={() => navigate(`/projects/${data.id}`)}
                        key={data._id}
                        className="item --card --pxy2 --text"
                        data-value={data._id}
                      >
                        <div className="--flex-dir-row">
                          <div sx={{ width: "150px" }}>
                            <img
                              height="130"
                              width="130"
                              src={`${backUrl}${data.image}`}
                              alt=""
                            />
                          </div>
                          <div className="--px2">
                            <h4>{data.title}</h4>
                            {shortenText(data.description, 100)}.....
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleProject;
